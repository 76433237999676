import React, { useState , useEffect  } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const VisaThankYouMessage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [imageLoaded, setImageLoaded] = useState(false);
  const backgroundImageSrc = `${process.env.PUBLIC_URL}/aboutUs/images/Statistics.jpg`;
  
  // Extract message and return path from location state
  const { message, returnPath } = location.state || { 
    message: 'Your visa request has been successfully sent. We will contact you shortly.', 
    returnPath: '/' // default return path
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleImageError = () => {
    setImageLoaded(false);
  };

  // Function to navigate to the return path
  const handleReturnClick = () => {
    navigate(returnPath);
  };

  return (
    <div className=" relative text-center py-12 rounded-lg overflow-hidden">
      {/* Preload and possibly display the image as a background */}
      <img 
        src={backgroundImageSrc}
        alt="Success Background"
        className="absolute inset-0 w-full h-full object-cover"
        onLoad={handleImageLoad}
        onError={handleImageError}
        style={{ display: imageLoaded ? 'none' : 'block' }} // Hide when loaded to show as background
      />
      {/* Overlay content */}
      <div className="absolute inset-0 bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${backgroundImageSrc})` }}>
          <div className="absolute inset-0 bg-sky-700 bg-opacity-50"></div>
        </div>
      {!imageLoaded && (
        <span className="sr-only">Success Background not available</span>
      )}
      {/* Message card */}
      <div className="relative z-10 p-8 bg-white bg-opacity-90 shadow-md rounded-lg max-w-2xl mx-auto mt-24">
        <h2 className="text-2xl font-bold text-green-800 mb-4 " style={{ fontFamily: 'Arial, sans-serif', textShadow: '1px 1px 1px rgba(0, 0, 0, 0.1)' }}>Thank You !!</h2>
        <p className="font-semibold text-xl tracking-wide mb-4 p-1" style={{ color: '#ffa500', fontFamily: 'Arial, sans-serif', textShadow: '1px 1px 1px rgba(0, 0, 0, 0.1)' }}>{message}</p>
        <button
          onClick={handleReturnClick}
          className="bg-blue-500 text-white py-2 px-6 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          Return Back
        </button>
      </div>
    </div>
  );
};

export default VisaThankYouMessage;
