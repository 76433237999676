import React from "react";
import Box from "@mui/material/Box";
import ReactPlayer from "react-player";

const VideoMaid = ({ video, style }) => {
  return (
    <Box
      sx={{
        width: { xs: "100vw", md: "1000px" },
        maxHeight: { xs: "100%", md: "100%" },
        display: 'flex',        // Enable flex container
        alignItems: 'center',   // Vertically center the content
        justifyContent: 'center', // Horizontally center the content
        ...style,
      }}
    >
      <ReactPlayer
        url={video}
        controls={true}
        width="97%"
        height="100%"
        playing={true}
      />
    </Box>
  );
};

export default VideoMaid;



// import React from "react";
// import { Player } from "video-react";
// import "video-react/dist/video-react.css";

// const VideoMaid = ({ video, style }) => {
//   return (
//     <div
//       style={{
//         width: "100%",
    
//         height: "100%",
//         maxHeight: "100%",
//         ...style,
//       }}
//     >
//       <Player autoPlay>
//         <source src={video} />
//       </Player>
//     </div>
//   );
// };

// export default VideoMaid;
