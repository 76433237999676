import { configureStore } from "@reduxjs/toolkit";

import ordersReducer from "../slices/orders/ordersSlices";

import usersReducer from "../slices/users/usersSlice";

import visaRequestReducer from "../slices/DH_visa/requestingDHvisa";

//store
const store = configureStore({
  reducer: {
    users: usersReducer,
    orders: ordersReducer,
    visa:visaRequestReducer
   
  },
});

export default store;
