import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import LoadingComponent from "../hooks/LoadingComp/LoadingComponent";
import { registerUserAction } from "../redux/slices/users/usersSlice";
import { Stack, TextField, InputAdornment, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import { useNavigate } from "react-router-dom";
import { MonthlyPaymentAction } from "../redux/slices/orders/ordersSlices";
import CircularProgress from "@mui/material/CircularProgress";

const GuestPaymentForm = (params) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //dispatch
  const [isLoading, setIsLoading] = useState(false);
  const maidData = params.params;
  const [formData, setFormData] = useState({
    fullname: "",
    phone: "",
    password: "",
  });

  const { fullname, phone, email } = formData;
  //---onchange handler----
  const onChangeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //---onsubmit handler----
  const onSubmitHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(
      registerUserAction({
        fullname,
        phone,
        email,
      })
      // .then(() => {
      //   setIsLoading(false);
      // })

      
    );
 
    navigate(
      `/maid-details?name=${maidData.name}&nationality=${
        maidData.nationality
      }&age=${maidData.age}&exp_country=${
        maidData.exp_country
      }&monthly_salary=${maidData.monthly_salary}&video_link=${
        maidData.video_link
      }&amount=${7}`
    );
  };

  // select store data including the token

  // Select the relevant state from Redux store
  const { user, error, loading } = useSelector((state) => state?.users);

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",

            height: "66vh",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={onSubmitHandler}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
        >
          <Typography
            variant="h4"
            component="h4"
            color="initial"
            sx={{
              fontSize: { xs: "16px", md: "20px" },
              width: "100% !important",
              mt: { xs: "20px !important", md: "0 !important" },
              mb: { xs: "20px !important", md: "42px !important" },
              textAlign: "center",
              color: "#939496",
              fontWeight: "bold",
            }}
          >
            Please enter your information to continue the payment process
          </Typography>
          {error && <h3 className="text-red-500">{error.message}</h3>}
          <Stack
            spacing={3}
            sx={{
              width: { xs: "300px !important", md: "500px !important" },
              ".MuiInputBase-root": {
                borderRadius: "30px",
              },
            }}
          >
            <TextField
              id="sign-in-fullname"
              fullWidth
              type="text"
              label="Full Name"
              sx={{ borderRadius: "30px" }}
              name="fullname"
              onChange={onChangeHandler}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              id="sign-in-phone"
              fullWidth
              type="text"
              label="PhoneNumber"
              sx={{ borderRadius: "30px" }}
              name="phone"
              onChange={onChangeHandler}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocalPhoneIcon />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              id="sign-in-email"
              fullWidth
              type="email"
              label="Email"
              sx={{ borderRadius: "30px" }}
              name="email"
              onChange={onChangeHandler}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />

            <Stack alignItems="center"></Stack>
            <div className="w-full px-4">
              {loading ? (
                <LoadingComponent />
              ) : (
                <Button
                  id="login-button"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{
                    borderRadius: "30px",
                  }}
                  disabled={!fullname || !phone || !email}
                >
                  Send
                </Button>
              )}
            </div>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default GuestPaymentForm;
