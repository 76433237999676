import * as React from "react";

import Typography from "@mui/material/Typography";
import theme from "../ui/theme/theme";

export const HomeCareCopyRight = () => {
  return (
    <Typography
      variant="body1"
      color="initial"
      sx={{
        fontSize: "11px",
        color: theme.palette.common.white,
        my: { xs: "10px", md: "15px" },
        pb: { xs: "5px", md: "15px" },
        textAlign: "center",
      }}
    >
      dubaihomes.care © 2016 - 2023 UAE license Number 730266
    </Typography>
  );
};

export default HomeCareCopyRight;
