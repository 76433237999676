import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerUserAction, verifyTheOtpAction , resendOtpAction} from "../redux/slices/users/usersSlice";
import { Stack, TextField, Button, Box, InputAdornment } from "@mui/material";
import { useAuth } from '../contexts/AuthContext';
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import BusinessIcon from "@mui/icons-material/Business";
import LoadingComponent from "../hooks/LoadingComp/LoadingComponent";
import PrivacyPolicyCheckbox from "./Privacy Policy/PrivacyPolicyCheckbox";
import ReCAPTCHA from "react-google-recaptcha";
import { useStore } from "../utils/store";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const { login } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const { selectedMaid } = useStore();
  const { loading, error } = useSelector((state) => state?.users);

  const [formData, setFormData] = useState({
    fullname: "",
    phone: "",
    address: "",
    email: "",
    termsAccepted :"",
    recaptchaValue: "",
  });
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const [timeLeft, setTimeLeft] = useState(null);
  const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(false);
  const handlePrivacyPolicyChange = (isChecked) => {
    setIsPrivacyPolicyChecked(isChecked);
  };

  useEffect(() => {
    const savedFormData = sessionStorage.getItem('signupFormData');
    if (savedFormData) {
        const parsedData = JSON.parse(savedFormData);
        setFormData(parsedData);
       // Clear the sessionStorage immediately after loading the data
        sessionStorage.removeItem('signupFormData');
    }
}, []);


  useEffect(() => {
    let timer = null;
    if (timeLeft > 0) {
      timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [timeLeft]);

  const cleanPhoneNumber = (phoneNumber) => phoneNumber.replace(/\s+/g, '').trim();
  const validatePhoneNumber = (phoneNumber) => /^05\d{8}$/.test(cleanPhoneNumber(phoneNumber));

  const handleTermsLinkClick = (event) => {
    event.preventDefault();
    sessionStorage.setItem('signupFormData', JSON.stringify(formData));
    navigate('/privacy-policy');
};

  

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Clear error messages when user starts editing the form again
    setErrorMessage("");
    if (name === 'phone') {
      setPhoneError("");  // Specifically clear phone errors when phone input changes
      if (!value.startsWith("05")) {
        setPhoneError("Phone number must start with '05'");
      } else if (!validatePhoneNumber(value)) {
        setPhoneError("Invalid phone number format");
      } else {
        setPhoneError("");
      }
    }
  };

  const onRecaptchaChange = (value) => {
    setFormData({ ...formData, recaptchaValue: value });
    setIsVerified(!!value);
  };

  const resendOtp = async () => {
    const cleanedPhone = cleanPhoneNumber(formData.phone);
    try {
      await dispatch(resendOtpAction({ phone: cleanedPhone })).unwrap();
      setTimeLeft(30); // Reset the timer
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    // Clear all error messages at the start of a new submission
    setErrorMessage("");
    setPhoneError("");
    if (!isPrivacyPolicyChecked) {
      setErrorMessage("Please you must accept the terms and conditions.");
      return;
    }
   
    if (!isVerified) {
      setErrorMessage("Please verify that you are not a robot.");
      return;
    }
    
    if (!otpSent) {
        if (validatePhoneNumber(formData.phone) ) {
        try {
          const cleanedPhone = cleanPhoneNumber(formData.phone);
          await dispatch(registerUserAction({
            ...formData,
            phone: cleanedPhone,
            termsAccepted: isPrivacyPolicyChecked  // Pass this as termsAccepted
          })).unwrap();
          setOtpSent(true);
          setTimeLeft(30); // Start the timer
          // Clear the error message if the registration starts successfully
          setErrorMessage("");
        } catch (error) {
           // Here you extract and set the error message from the backend
           if (error.message) {
            setErrorMessage(error.message);  // Directly use error.message if that's where the message is
          } else {
            setErrorMessage("Registration failed. Please try again.");
          }
        } 
      } else {
        setPhoneError('Invalid phone number format');
      }
    } else {
      try {
        const response = await dispatch(verifyTheOtpAction({ phone: formData.phone, otp })).unwrap();
        if (response.status === "success") {
          sessionStorage.removeItem('signupFormData');
          // Use the login function from AuthContext
          login(response.userFound.fullname);
  
          // Redirect the user based on your application's needs
          if (!selectedMaid) {
            navigate('/'); // Replace with your success route
          } else {
            navigate('/maid-details'); // Replace with your success route
          }
        } else {
          // If the response does not indicate success
          setErrorMessage(response.message || "Verification failed. Please try again.");
        }
      } catch (error) {
        console.error("OTP verification error:", error);
        // Handle OTP verification failure
      }
    }
  };

  return (
    <>
      {error && <h3 className="text-red-500">{errorMessage}</h3>}
      {/* {error && <h3 className="text-red-500">{phoneError}</h3>} */}
      {/* {error && <h3 className="text-red-500">{error.message}</h3>} */}
      <Box component="form" sx={{ "& > :not(style)": { m: 1, width: "25ch" } }} noValidate autoComplete="off" onSubmit={onSubmitHandler}>
        <Stack spacing={3} sx={{ width: { xs: "300px !important", md: "500px !important" }, ".MuiInputBase-root": { borderRadius: "30px" } }}>
          {!otpSent && (
            <>
              <TextField id="sign-in-fullname" fullWidth type="text" label="Full Name" name="fullname" value={formData.fullname} onChange={onChangeHandler} InputProps={{ startAdornment: <InputAdornment position="start"><PersonIcon /></InputAdornment> }} />
              <TextField id="sign-in-phone" fullWidth type="tel" label="Phone Number" name="phone" value={formData.phone} onChange={onChangeHandler} error={!!phoneError} helperText={phoneError} InputProps={{ startAdornment: <InputAdornment position="start"><LocalPhoneIcon /></InputAdornment> }} />
              <TextField id="sign-in-email" fullWidth type="email" label="Email" name="email" value={formData.email} onChange={onChangeHandler} InputProps={{ startAdornment: <InputAdornment position="start"><EmailIcon /></InputAdornment> }} />
              <TextField id="sign-in-address" fullWidth type="text" label="Address" name="address"  value={formData.address} onChange={onChangeHandler} InputProps={{ startAdornment: <InputAdornment position="start"><BusinessIcon /></InputAdornment> }} />
              <PrivacyPolicyCheckbox
                onCheckChange={handlePrivacyPolicyChange}
                isChecked={isPrivacyPolicyChecked}
                handleTermsLinkClick={handleTermsLinkClick} // New prop for handling click
              />
              <ReCAPTCHA  sitekey="6Le2EWwpAAAAAL7p6bYBZf57evb3cl6LSHXjIkcD" onChange={onRecaptchaChange} />
              <Button type="submit" variant="contained" sx={{ borderRadius: "30px" }} disabled={loading || !formData.fullname || !formData.phone || !formData.email || !formData.address || !isVerified || !!phoneError || !isPrivacyPolicyChecked}>SIGN UP</Button>
            </>
          )}
          {otpSent && (
            <>
              <TextField  type="tel"  label="OTP" value={otp} onChange={(e) => setOtp(e.target.value)} fullWidth InputProps={{ startAdornment: <InputAdornment position="start"><LocalPhoneIcon /></InputAdornment> }} />
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                {timeLeft > 0 ? (
                  <p>You can resend OTP in {timeLeft} seconds</p>
                ) : (
                  <Button onClick={resendOtp} disabled={loading}>Resend Code</Button>
                )}
              </div>
              <Button type="submit" variant="contained" sx={{ borderRadius: "30px" }} disabled={loading || !otp}>Verify OTP</Button>
            </>
          )}
        </Stack>
      </Box>
      {loading && <LoadingComponent />}
    </>
  );
};

export default SignUp;