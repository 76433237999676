import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import EmailIcon from "@mui/icons-material/Email";
import theme from "../ui/theme/theme";
export const Map = (ServiceItemProps) => {
  const findUsItem = {
    mb: "30px",
    "& svg": {
      color: theme.palette.secondary.main,
      fontSize: { md: "50px", xs: "30px" },
      mr: "10px",
    },
    "& h3": {
      color: theme.palette.secondary.main,
      textTransform: "uppercase",
      fontWeight: "700",
      fontSize: "15px",
    },
    "& p": {},
  };
  return (
    <Box
      sx={{
        backgroundColor: "#f1f1f2",
      }}
    >
      <Box
        sx={{
          display: "flex",
          maxWidth: "1500px",
          alignItems: "center",
          p: { xs: "10px", md: "40px" },
          mb: { xs: "20px", md: "0" },
          mx: "auto",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "30%" },
            px: { xs: "15px", md: "23px" },
            py: { xs: "30px", md: "70px" },
            border: `1px solid ${theme.palette.secondary.main}`,
            borderRadius: "37px",
            transform: { xs: "translateX(0px)", md: "translateX(10px)" },
            zIndex: 1,
            background: "white",
            mb: { xs: "20px", md: "0px" },
          }}
        >
          <Typography
            component="h2"
            variant="h2"
            color="initial"
            sx={{
              fontWeight: "bold",
              textTransform: "uppercase",
              fontSize: { xs: "35px", md: "60px" },
              mb: { xs: "20px", md: "50px" },

              "& span": {
                color: theme.palette.secondary.main,
              },
            }}
          >
            find
            <span> us</span>
          </Typography>
          <Stack direction="row" alignItems="center" sx={findUsItem}>
            <LocationOnIcon />
            <div>
              <h3>address</h3>
              <p>Dubai . Al Wasl Road, Villa 462</p>
            </div>
          </Stack>
          <Stack direction="row" alignItems="center" sx={findUsItem}>
            <EmailIcon />
            <div>
              <h3>E-mail</h3>
              <p>info@homecaremaid.ae</p>
            </div>
          </Stack>
          <Stack direction="row" alignItems="center" sx={findUsItem}>
            <PhoneEnabledIcon />
            <div>
              <h3>Phone</h3>
              <p>048103800</p>
            </div>
          </Stack>
        </Box>
        <Box
          className="footer-content"
          sx={{
            width: { xs: "100%", md: "70%" },
            boxShadow: "5px 7px 21px 13px #bbbb",
            borderRadius: { xs: "22px", md: "0 22px 22px 0" },
            overflow: "hidden",
          }}
        >
          <div style={{ position: "relative", overflow: "hidden" }}>
            <iframe
              title="Tadbeer Home Care Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.9909996362115!2d55.248484499999994!3d25.199872299999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43cabc5c93d3%3A0xf527fe7c72155d27!2sTadbeer%20Home%20Care!5e0!3m2!1sen!2sus!4v1567650711551!5m2!1sen!2sus"
              style={{
                width: "100%",
                height: "400px",
                border: "none",
              }}
              allowFullScreen=""
              loading="lazy"
            />
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default Map;
