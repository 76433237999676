import React from "react";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { TextField, InputAdornment, Typography, Button, Box  } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";


import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";

export default function CustomerDetails({ email, dateJoined, fullName }) {
  const navigate = useNavigate(); // Hook for navigation

  // Function to handle navigation
  const handleQuickPayment = () => {
    navigate('/quickpayment'); // Navigate programmatically
  };
  return (
    <Box
      sx={{
        width: { xs: "300px !important", md: "500px !important" },
        pt: "40px",
        ".MuiInputBase-root": {
          borderRadius: "30px",
          mb: "30px",
        },
        input: { "-webkit-text-fill-color": "black !important" },
      }}
    >
      <Typography
        variant="h1"
        component="h1"
        color="initial"
        sx={{
          fontSize: "20px",
          textAlign: "center",
          fontWeight: "bold",
          mb: "40px",
        }}
      >
        My Info
      </Typography>
      <TextField
        id="sign-in-fullname"
        fullWidth
        type="text"
        label="Full Name"
        sx={{ borderRadius: "30px" }}
        name="fullName"
        value={fullName}
        disabled
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          ),
        }}
      />

      <TextField
        id="sign-in-email"
        fullWidth
        value={email}
        type="email"
        label="Email"
        sx={{ borderRadius: "30px" }}
        disabled
        name="email"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        id="sign-in-dateJoined"
        fullWidth
        value={dateJoined}
        type="text"
        label="Date Joined"
        sx={{ borderRadius: "30px" }}
        disabled
        name="dateJoined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <InsertInvitationIcon />
            </InputAdornment>
          ),
        }}
      />
      <Button
        onClick={handleQuickPayment}
        variant="contained"
        color="primary"
        sx={{
          mt: 3, // Margin top for spacing
          borderRadius: "20px", // Rounded edges
        }}
      >
        Make Quick Payment
      </Button>
    </Box>
  );
}
