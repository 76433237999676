// usePageViews.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePageViews = () => {
  const location = useLocation();

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pageview',
      page: location.pathname + location.search,
    });
  }, [location]);
};

export default usePageViews;
