import React from "react";
import styled from "styled-components";

import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import theme from "../ui/theme/theme";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import { PATHS } from "../constants/routes.ts";
import CallIcon from "@mui/icons-material/Call";
import EastIcon from "@mui/icons-material/East";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import EmailIcon from "@mui/icons-material/Email";
import FooterSocialMediaLinks from "../components/footer-Social-media-links";
import HomeCareCopyRight from "../components/home-care-copy-right.jsx";

const FooterContainer = styled.footer`
  background-color: #333;
  color: white;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;

  @media (max-width: 768px) {
    padding: 10px 0;
  }
`;

function Footer() {
  const location = useLocation();

  if (location.pathname === "/package4") {
    return null;
  }

  if (location.pathname === "/maid-details") {
    return null;
  }

  if (location.pathname === "/get-visa") {
    return null;
  }

  
  if (location.pathname === "/login") {
    return null;
  }


  const footerBtnFirstIcon = {
    color: "#a5a7ab",
    fontSize: "19px",
    mr: "10px",
  };
  const footerBtn = {
    mb: "15px",
    background: theme.palette.common.white,
    color: theme.palette.common.black,
    width: "300px",
    height: "40",
    fontWeight: "500",
    ":hover": {
      color: theme.palette.common.white,
      "& svg:last-of-type ": {
        transition: "ease-in-out .2s",
        transform: "translateX(10px)",
      },
    },
  };
  const footerBtnSecondIcon = {
    color: theme.palette.secondary.main,
    ml: "auto",
    fontSize: "15px",
  };
  const footerBtnDiv = {
    textAlign: "left",
    lineHeight: "14px",
    textTransform: "capitalize",
    fontSize: "14px",
  };
  const footerBtnSpan = {
    fontSize: "11px",
    color: "#a5a7ab",
  };
  const footerTitle = {
    color: theme.palette.common.white,
    fontSize: "18px",
    mb: "20px",
    mt: { xs: "20px", mb: "65px" },
  };
  return (
    <Box
      id="footer"
      sx={{
        background: "#7c93b7",

        textAlign: "center",
        py: { xs: "20px", md: "30px" },
      }}
    >
      <Box sx={{ maxWidth: "1400px" }}>
        <Grid container spacing={2} sx={{}}>
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            sx={
              {
                // display: " flex",
                // flexDirection: "column",
                // alignItems: "center",
                // justifyContent: "center",
              }
            }
          >
            <Typography
              component="h4"
              variant="h4"
              color="initial"
              sx={footerTitle}
            >
              Our Services
            </Typography>
            <div>
              <Link to={PATHS.PACKAGES}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mb: "15px", px: "25px" }}
                >
                  Get A Maid
                </Button>
              </Link>
            </div>
            <div>
              <Link to={PATHS.GET_VISA}>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ color: theme.palette.common.white, mb: "20px", px: "25px" }}
                >
                  Get A Visa
                </Button>
              </Link>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            // sx={{ display: "flex", justifyContent: "center" }}
          >
            <Typography
              component="h5"
              variant="h5"
              color="initial"
              sx={footerTitle}
            >
              Contact Us
            </Typography>
            <div>
              <Link to="tel:048103800">
                <Button variant="contained" color="primary" sx={footerBtn}>
                  <CallIcon
                    sx={{
                      ...footerBtnFirstIcon,
                      color: theme.palette.secondary.main,
                    }}
                  />
                  <span>Call</span>
                  <EastIcon sx={footerBtnSecondIcon} />
                </Button>
              </Link>
            </div>
            
            <div>
              <Link to="https://api.whatsapp.com/send?phone=%2B97148103800" target="_blank">
                <Button variant="contained" color="primary" sx={footerBtn}>
                  <WhatsAppIcon
                    sx={{ ...footerBtnFirstIcon, color: " #60d668"}}
                  />
                  <span>whatsapp</span>
                  <EastIcon sx={footerBtnSecondIcon} />
                </Button>
              </Link>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            // sx={{ display: "flex", justifyContent: "center" }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-evenly"
              sx={{ mb: "30px" }}
            >
              <Box
                component="img"
                src="/images/footer-logo.png"
                alt="footer-logo"
                sx={{
                  width: "75px",
                  objectFit: "contain",
                }}
              />
               <Box
                component="img"
                src="/images/Khorfakkan.png"
                alt="KHORFAKKAN"
                sx={{
                  width: "75px",
                  objectFit: "contain",
                }}
              />
              <Box
                component="img"
                src="/images/FamilyCare.png"
                alt="FamilyCare"
                sx={{
                  width: "75px",
                  objectFit: "contain",
                }}
              />
              <Box
                component="img"
                src="/images/tadbeer-logo.png"
                alt="tadbeer-logo"
                sx={{
                  width: "75px",
                  objectFit: "contain",
                }}
              />
            </Stack>
            <div>
              <Link to="https://shorturl.at/eFPR4" target="_blank">
                <Button variant="contained" color="primary" sx={footerBtn}>
                  <LocationOnIcon sx={{ ...footerBtnFirstIcon }} />
                  <div style={footerBtnDiv}>
                    <span style={footerBtnSpan}>Get Direction</span>
                    <p>Dubai . Al Wasl Road, Villa 462</p>
                  </div>
                  <EastIcon sx={footerBtnSecondIcon} />
                </Button>
              </Link>
            </div>

            <div>
              <a href="tel:048103800">
                <Button variant="contained" color="primary" sx={footerBtn}>
                  <PhoneEnabledIcon sx={{ ...footerBtnFirstIcon }} />
                  <div style={footerBtnDiv}>
                    <span style={footerBtnSpan}>Call Us</span>
                    <p>048103800</p>
                  </div>
                  <EastIcon sx={footerBtnSecondIcon} />
                </Button>
              </a>
            </div>

            <div>
              <a href="mailto:info@homecaremaid.ae" target="_blank" rel="noopener noreferrer">
                <Button variant="contained" color="primary" sx={footerBtn}>
                  <EmailIcon sx={{ ...footerBtnFirstIcon }} />
                  <div style={footerBtnDiv}>
                    <span style={footerBtnSpan}>Contact Us</span>
                    <p style={{ textTransform: "lowercase" }}>info@homecaremaid.ae</p>
                  </div>
                  <EastIcon sx={footerBtnSecondIcon} />
                </Button>
              </a>
            </div>
          </Grid>
        </Grid>
        <FooterSocialMediaLinks />
        <Box
          component="hr"
          height="1px"
          backgroundColor="#bbbb"
          width="90%"
          margin="auto"
        />
        <HomeCareCopyRight />
      </Box>
    </Box>
  );
}

export default Footer;
