import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FooterSocialMediaLinks from "../../components/footer-Social-media-links";
import HomeCareCopyRight from "../../components/home-care-copy-right";
import { Helmet } from 'react-helmet-async'; // Import Helmet
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SignIn from "../../components/sign-in";
import SignUp from "../../components/sign-up";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
const Login = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <Helmet>
      <title>Tadbeer Homecare</title>
        <meta name="description" 
          content="Homecare is your one stop destination to find a solution to all your domestic workers visa services requirements , hiring full-time maids, Sign &amp; Pay online in 5 minutes." 
          data-rh="true"
        />
        <link rel='canonical' href='/Login' />
      </Helmet>
      <Grid
        container
        spacing={2}
        sx={{ mt: 0, flexDirection: { xs: "column-reverse", md: "row" } }}
      >
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            background:
              "repeating-linear-gradient(0deg, #0252d2, #1765e2,#216eea,#2d7af4,#317df8,#2677f9,#2074f9,#1d72f9)",
          }}
        >
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="space-around"
            sx={{
              height: { xs: "calc(60vh - 116px)", md: "calc(100vh - 116px)" },
            }}
          >
            <Box
              component="img"
              src="/images/login-logo.png"
              alt="login Logo"
              sx={{
                width: { xs: "90px", md: "125px" },
                height: "auto",
              }}
            />
            <div>
              <Typography
                component="h1"
                variant="h1"
                color="initial"
                sx={{
                  color: "#dedede",
                  textTransform: "uppercase",
                  fontSize: { xs: "40px", md: "55px" },
                  fontWeight: "bold",
                }}
              >
                Welcome
              </Typography>
              <Typography
                variant="body1"
                color="initial"
                sx={{ color: "#dedede", fontSize: { xs: "18px", md: "23px" } }}
              >
                We take care of your home
              </Typography>
            </div>
            <div>
              <FooterSocialMediaLinks />

              <HomeCareCopyRight />
            </div>
          </Stack>
        </Grid>
        <Grid item xs={12} md={7}>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ height: "100%" }}
          >
            <AppBar
              position="static"
              sx={{
                background: "#ebebeb",
                color: "#838383",
                width: "300px",
                borderRadius: "30px",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                sx={{
                  "& .Mui-selected": {
                    background: "#000000",
                    borderRadius: "30px",
                    border: "none",
                    color: theme.palette.common.white,
                  },
                  "& .MuiTabs-indicator": {
                    display: "none",
                  },
                }}
              >
                <Tab label="Sign in" {...a11yProps(0)} />
                <Tab label="Sign up" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
              sx={{ width: "100%" }}
            >
              <TabPanel
                value={value}
                index={0}
                dir={theme.direction}
                sx={{ width: "100%" }}
              >
                <SignIn />
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <SignUp />
              </TabPanel>
            </SwipeableViews>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
