import React, { useState , useEffect} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLocation , useNavigate} from "react-router";
import { MonthlyPaymentAction } from "../../redux/slices/orders/ordersSlices";
import theme from "../../ui/theme/theme";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { useStore } from "../../utils/store"
import { Helmet } from 'react-helmet-async'; // Import Helmet


const MaidDetails = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useState(() => {
    // Initialize state directly from local storage
    const userInfo = localStorage.getItem("userInfo");
    return userInfo ? JSON.parse(userInfo) : null;
  });

  const selectedMaid = useStore(state => state.selectedMaid);

  useEffect(() => {
    const handleStorageChange = () => {
      const userInfo = localStorage.getItem("userInfo");
      setUser(userInfo ? JSON.parse(userInfo) : null);
    };

    // Add event listener for local storage changes
    window.addEventListener('storage', handleStorageChange);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);



   // Redirect back if no maid is selected
   useEffect(() => {
    if (!selectedMaid) {
      navigate('/package4'); // Replace with your appropriate route
    }
  }, [selectedMaid, navigate]);

  // Check if a maid is selected
  if (!selectedMaid) {
    // Display a message or a specific UI element
    return (
      <Box sx={{ padding: '20px', textAlign: 'center' }}>
        <Typography variant="h6">
          No maid selected. Please go back and select a maid.
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => navigate(-1)} // Navigate back
          sx={{ mt: 3 }}
        >
          Go Back
        </Button>
      </Box>
    );
  }

  const isLoggedIn = user?.token ? true : false;

  const handleHireClick = () => {
    if (!isLoggedIn) {
      navigate(`/login`);
      return;
    }
  
    // const paymentWindow = window.open('', '_blank'); // Open a blank window immediately
  
    setIsLoading(true);
    const maidFromErp = selectedMaid.name;
    let amount = selectedMaid.amount;
  
    dispatch(MonthlyPaymentAction({ maidFromErp, amount }))
      .then((response) => {
        const url = response.payload; // Accessing the URL from the response object
        if (url && url.startsWith('http')) {
          window.location.href = url; // Correctly handle external URLs
        } else {
          console.error('Payment URL is null');
          // paymentWindow.close();
        }
        setIsLoading(false);
      })
      .catch((error) => {
        // paymentWindow.close();
        setIsLoading(false);
        console.error("Error during hiring process:", error);
      });
  };
  



  const fareStyle = {
    mt: "20px",
    color: "#737373",
    fontSize: { xs: "14px", md: "16px" },

    span: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      fontSize: { xs: "20px", md: "30px" },
    },
    sup: {
      fontSize: { xs: "11px", md: "13px" },
    },
  };

  return (
    <>
      <Helmet>
        <meta name="description" 
          content="Homecare is your one stop destination to find a solution to all your domestic workers visa services requirements , hiring full-time maids, Sign &amp; Pay online in 5 minutes." 
          data-rh="true"
        />
        <link rel='canonical' href='/maid-details' />
      </Helmet>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",

            height: "66vh",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ maxWidth: "1050px", mx: "auto", pb: "30px" }}>
          <Typography
            variant="h1"
            component="h1"
            color="initial"
            sx={{
              fontSize: { xs: "25px", md: "30px" },
              fontWeight: "bold",
              textTransform: "uppercase",
              my: "30px",
              marginLeft: "16px", 
            }}
          >
            hire and payment
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{ background: theme.palette.common.white }}
          >
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                textAlign: { xs: "-webkit-center", md: "-webkit-right" },
                pl: "0 !important",
                pt: "0 !important",
              }}
            >
              <Box
                style={{
                  width: { xs: "310px", md: "350px" },
                  boxShadow: "1px 1px 11px 1px #bbb6",
                  padding: "10px",
                }}
              >
                {selectedMaid?.img ? (
                  <div style={{ 
                    maxHeight: "200px",
                    display: 'flex',         // Set the div as a flex container
                    alignItems: 'center',    // Vertically center the content within the div
                    justifyContent: 'center' // Horizontally center the content within the div
                }}>
                    <img
                        src={selectedMaid.img}
                        style={{
                            width: "95%",
                            minWidth: "95%",
                            height: "170px",
                            objectFit: "scale-down",
                            background: "white",
                          
                            borderRadius: "15px",
                          
                            maxWidth: '100%',
                            maxHeight: '100%',
                        }}
                    />
                </div>
                ) : (
                  <Box
                    sx={{
                      height: "200px",
                      width: "100%",
                      background: "#bbbb",
                    }}
                  ></Box>
                )}

                <div style={{}} className="text-center mt-4">
                  <Box
                    item
                    xs={12}
                    md={8}
                    sx={{ width: "100%", p: "20px !important" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h2
                        className="text-xl font-semibold"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "15px",
                          textTransform: "capitalize",
                          fontWeight: "bold",
                          marginBottom: "10px",
                        }}
                      >
                        {selectedMaid.name}
                      </h2>
                    </Box>
                    <Box
                      sx={{
                        "& p": {
                          background: "#bbb6 !important",
                          color: theme.palette.primary.main,
                          fontSize: "13px",
                          display: "table",
                          marginBottom: "10px",
                          paddingInline: "10px",
                        },
                      }}
                    >
                      <p className="text-gray-600 ">
                        {" "}
                        <span style={{ fontWeight: "bold" }}>
                          Nationality:
                        </span>{" "}
                        {selectedMaid.nationality}
                      </p>
                      <p className="text-gray-600">
                        <span style={{ fontWeight: "bold" }}> Age: </span>
                        {selectedMaid.age}
                      </p>
                      <p className="text-gray-600">
                        <span style={{ fontWeight: "bold" }}>Country-Exp:</span>{" "}
                        {selectedMaid.exp_country}
                      </p>
                      <Box
                        sx={{
                          display: "flex",

                          a: {
                            display: "flex",
                          },
                          svg: {
                            width: " 28px",
                            height: " 28px",
                            fontSize: " 23px",

                            display: " flex",
                            justifyContent: " center",
                            alignItems: " center",
                            borderRadius: " 100%",
                            p: "5px",
                            color: theme.palette.common.white,
                            ml: "5px",
                            mt: "20px",
                            ":hover": {
                              transform: "scale(1.2)",
                              transition: "ease-in-out .2s",
                            },
                          },
                        }}
                      >
                      </Box>
                    </Box>
                  </Box>
                </div>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                "h2,p": { pl: { xs: "10px", md: "0px" } },
              }}
            >
              <Typography
                variant="h2"
                component="h2"
                color="initial"
                sx={{
                  fontSize: { xs: "18px", md: "25px" },
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  mt: "20px",
                }}
              >
                bank payment:
              </Typography>
              <Typography
                variant="body1"
                component="p"
                color="initial"
                sx={fareStyle}
              >
              </Typography>
              <Typography
                variant="body1"
                component="p"
                color="initial"
                sx={fareStyle}
              >
                <span>
                  <sup>AED </sup>
                  {selectedMaid?.amount} /MONTH
                </span>{" "}
                on the {new Date().getDate()}<sup>th</sup> of each month
              </Typography>
              <Box
                sx={{
                  background: "#f2f2f2",
                  transform: { xs: "translateX(0px)", md: "translateX(-15px)" },
                  width: { xs: "100%", md: "calc(100% + 15px)" },
                  mt: "30px",
                  p: { xs: "15px", md: "25px" },
                  pb: "15px",
                  h2: {
                    fontSize: { xs: "17px", md: "22px" },
                    fontWeight: "bold",
                    mb: "15px",
                  },
                }}
              >
                <h2>PRICE INCLUDES:</h2>
                <Stack
                  direction="row"
                  sx={{
                    justifyContent: "space-between",
                    flexWrap: "wrap",

                    p: { fontSize: "14px" },
                    div: {
                      display: "flex",
                      mr: "10px",
                      mb: "10px",
                    },
                    img: {
                      width: { xs: "35px", md: "45px" },
                      height: { xs: "35px", md: "45px" },
                      objectFit: "contain",
                      mr: "5px",
                    },
                  }}
                >
                  <div>
                    <Box
                      component="img"
                      src="/images/maid-details/maid-details-1.png"
                      alt="maid-details"
                    />
                    <p>
                      Salary &
                      <br />
                      ATM card
                    </p>
                  </div>
                  <div>
                    <Box
                      component="img"
                      src="/images/maid-details/maid-details-2.png"
                      alt="maid-details"
                    />
                    <p>Medicals</p>
                  </div>
                  <div>
                    <Box
                      component="img"
                      src="/images/maid-details/maid-details-3.png"
                      alt="maid-details"
                    />
                    <p>
                      Residency
                      <br />
                      visa & EID
                    </p>
                  </div>
                  <div>
                    <Box
                      component="img"
                      src="/images/maid-details/maid-details-4.png"
                      alt="maid-details"
                    />
                    <p>
                      24/7 client
                      <br />
                      support
                    </p>
                  </div>
                </Stack>
              </Box>
              <div style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleHireClick}
                  sx={{
                    textTransform: "capitalize",
                    mt: "30px",
                    borderRadius: "30px",
                    mx: "20px",
                  }}
                >
                  Hire {selectedMaid.name} & Pay
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default MaidDetails;
