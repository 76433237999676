import React, { useEffect, useState } from "react";
import Pagination from '@mui/material/Pagination';
import { useDispatch, useSelector } from "react-redux";
import CustomerDetails from "./CustomerDetails";
import { getUserProfileAction } from "../../redux/slices/users/usersSlice";
import PaymentDetails from "./PaymentDetaiils";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PersonIcon from "@mui/icons-material/Person";
import Person3Icon from "@mui/icons-material/Person3";
import PaymentIcon from "@mui/icons-material/Payment";
import theme from "../../ui/theme/theme";
import { Helmet } from 'react-helmet-async'; // Import Helmet
// import { useLocation } from 'react-router-dom';
// import Swal from 'sweetalert2';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function CustomerProfile() {
  
  // const location = useLocation();
//   useEffect(() => {
//     const queryParams = new URLSearchParams(location.search);
//     const paymentSuccess = queryParams.get('paymentSuccess');

//     if (paymentSuccess) {
//         Swal.fire({
//             title: 'Success!',
//             text: 'Payment processed successfully!',
//             icon: 'success',
//             confirmButtonText: 'Great'
//         }).then(() => {
//             // Remove the query parameter from the URL
//             queryParams.delete('paymentSuccess');
//             window.history.replaceState({}, '', `${location.pathname}?${queryParams}`);
//         });
//     }
// }, [location]);


  const [isMobile, setIsMobile] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [value, setValue] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;
  const dispatch = useDispatch();
  const { error, loading, profile } = useSelector((state) => state?.users);
  const monthlySubscriptions = profile?.user?.monthlySubscriptions ? profile.user.monthlySubscriptions.map(sub => ({
    ...sub,
    type: 'monthly'
  })) : [];
  const quickPayments = profile?.user?.quickPayments ? profile.user.quickPayments.map(qp => ({
    ...qp,
    type: 'quick'
  })) : [];


 

  const allPayments = [...monthlySubscriptions, ...quickPayments]
  .sort((a, b) => {
    // First, compare the dates
    const dateComparison = new Date(b.createdAt) - new Date(a.createdAt);
    if (dateComparison !== 0) return dateComparison;

    // If the dates are the same, then sort by another field like paymentNumber.
    // Assuming paymentNumber is a string that can be compared lexically:
    return a.paymentNumber.localeCompare(b.paymentNumber);
  });


  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = allPayments.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // Determine if the current screen size is considered mobile
  const handleResize = () => {
    setIsMobile(window.innerWidth < theme.breakpoints.values.md);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Helmet>
        <title>Homecare My Profile</title>
        <meta name="description" 
          content="Homecare is your one stop destination to find a solution to all your domestic workers visa services requirements , hiring full-time maids, Sign &amp; Pay online in 5 minutes." 
        />
        <link rel='canonical' href='/customer-profile' />
      </Helmet>
      <Box
            sx={{
              flexGrow: 1,
              bgcolor: "background.paper",
              display: "flex",
              height: "calc(100vh - 110px)",
              flexDirection: { xs: "column", md: "row" },
              ".MuiTabs-vertical": {
                overflow: "inherit",
              },
              ".MuiTabs-scroller": {
                boxShadow: "1px 1px 7px 1px #bbbbbb57",
              },
            }}
          >
            <Tabs
                orientation={isMobile ? 'horizontal' : 'vertical'}
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Tabs example"
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'row' : 'column',
                  width: isMobile ? '100%' : '220px',
                  '.MuiTabs-flexContainer': {
                    flexDirection: isMobile ? 'row' : 'column',
                    width: '100%',
                  },
                  '.MuiTab-root': {
                    width: isMobile ? 'auto' : '100%',
                  },
                  borderBottom: isMobile ? 1 : 0,
                  borderColor: 'divider',
                  button: { fontSize: { xs: '16px', md: '20px' } },
                  ".MuiTab-labelIcon": {
                    justifyContent: "flex-start",
                    textTransform: "capitalize",
                  },
                  svg: { fontSize: { xs: '16px', md: '18px' } },
                  ".Mui-selected": {
                    backgroundColor: theme.palette.primary.main,
                    color: `${theme.palette.common.white} !important`,
                  },
                }}
              >
                <Tab label="My payments" {...a11yProps(1)} icon={<PaymentIcon />} />
                <Tab label="My Info" {...a11yProps(0)} icon={<PersonIcon />} />
                <Tab label="My maids" {...a11yProps(2)} icon={<Person3Icon />} />
          </Tabs>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflow: 'auto',
            maxHeight: 'calc(100vh - 110px)',
            pt: 10,
          }}
        >
          
          <TabPanel value={value} index={0}>
            <Typography
                variant="h4"
                component="h4"
                color="initial"
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  mb: "40px",
                }}
              >
                Payment Details
            </Typography>
            <Box sx={{ width: '100%', maxWidth: '800px', margin: 'auto' }}> {/* Adjust width as needed */}
            {currentItems.map((payment) => (
              <PaymentDetails 
                key={payment._id}
                date={new Date(payment.createdAt).toLocaleDateString()}
                amount={payment.amount}
                description={payment.paymentMethod || 'N/A'}
                status={payment.paymentStatus}
                paymentNumber={payment.paymentNumber}
                worker={payment.maidFromErp}
                type={payment.type} // This will be 'monthly' or 'quick'
              />
            ))}

            <Pagination
              count={Math.ceil(allPayments.length / itemsPerPage)}
              page={currentPage}
              onChange={handleChangePage}
              color="primary"
            />
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            {loading ? (
              <Typography>Loading...</Typography>
            ) : error ? (
              <Typography color="error">{error?.message}</Typography>
            ) : (
              <CustomerDetails
                email={profile?.user?.email}
                dateJoined={new Date(profile?.user?.createdAt).toDateString()}
                fullName={profile?.user?.fullname}
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Typography>My maids</Typography>
          </TabPanel>
        </Box>
      </Box>
    </>
  );
}
