import * as React from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const AssistantItem = (AssistantItemProps) => {
  return (
    <Box
      component="div"
      sx={{
        background: " white",
        padding: "7px",
        borderRadius: "15px",
        border: "1px solid #bbbb",
        mr: "6px",
        fontSize: { xs: "14px", md: "16px" },
      }}
    >
      <Box
        component="img"
        src={AssistantItemProps.icon}
        alt="assistant-icon-1"
        sx={{ height: "40px", width: "auto" }}
      />
      <Typography variant="span" color="initial">
        {AssistantItemProps.title}
      </Typography>
    </Box>
  );
};

export default AssistantItem;
