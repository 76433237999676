import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import theme from "../ui/theme/theme.js";
import Faq from "../components/faq.jsx";
import "swiper/css";

export default function FaqPage() {
  return (
    <Box
      sx={{
        background: "#f7faff",
        py: "30px",
        px: { xs: "10px", md: "40px" },

        backgroundSize: "cover",
      }}
    >
      <Typography
        component="h2"
        variant="h2"
        color="initial"
        sx={{
          fontWeight: "bold",
          textTransform: "uppercase",
          textAlign: "center",
          fontSize: { xs: "35px", md: "60px" },

          "& span": {
            color: theme.palette.primary.main,
          },
        }}
      >
        <span> faq </span>
        questions
      </Typography>
      <Typography
        component="p"
        variant="body1"
        color="initial"
        sx={{
          textAlign: "center",
          my: { xs: "10px", md: "30px" },
        }}
      >
        Exclusive message, Insight and Answers
      </Typography>
      <Box
        sx={{
          maxWidth: "1300px",
          mx: "auto",
        }}
      >
        <Faq />
      </Box>
    </Box>
  );
}
