import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileAction } from "../../redux/slices/users/usersSlice";
import VirtualizedSelect from "react-select-virtualized";
import { QuickPaymentAction } from "../../redux/slices/orders/ordersSlices";
import LoadingComponent from "../../hooks/LoadingComp/LoadingComponent";
import axios from "axios";
import { Helmet } from 'react-helmet-async'; // Import Helmet



export default function QuickPayment() {
  const [paymentAmount, setPaymentAmount] = useState("");
  const [selectedMaid, setSelectedMaid] = useState(null); 

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);
  
  const {  error, profile } = useSelector((state) => state?.users);
  const user = profile?.user;
  
  const shippingAddress = user?.shippingAddress;
  
 const placeOrderHandler = async () => {
  try {
    const stripeUrl = await dispatch(
      QuickPaymentAction({
        shippingAddress,
        maidFromErp: selectedMaid,
        amount: paymentAmount,
      })
    ).unwrap();  // Assuming you are using Redux Toolkit's async thunks

    // This is where you handle the Stripe URL response
    // const stripeUrl = response.url;  // Assuming the response contains a URL
    if (stripeUrl) {
      window.location.href = stripeUrl;  // Redirect within the same tab
    } else {
      console.error('No payment URL received');
    }
  } catch (error) {
    console.error('Error processing payment:', error);
  }
};

  
  const { loading: orderLoading, error: orderErr } = useSelector(
    (state) => state?.orders
  );

  const [maidsList, setMaidsList] = useState([]);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://homecaredxb.me/api/v1/Maids?api-key=8c44w8kcocsw8gg0c4scwg4oogc8go4sgso4ccso&status[]=approved&status[]=pending&status[]=hired"
        );
        setMaidsList(response.data.response);
        setLoad(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoad(false);
      }
    };

    fetchData();
  }, []);


  return (
    <>
      <Helmet>
        <title>Homecare Quick Payment</title>
        <meta name="description" 
          content="Homecare is your one stop destination to find a solution to all your domestic workers visa services requirements , hiring full-time maids, Sign &amp; Pay online in 5 minutes." 
        />
        <link rel='canonical' href='/quickpayment' />
      </Helmet>
      {orderErr && error && <h3 className="text-red-500">{error.message}</h3>}

      <div className="bg-gray-50">
        <main className="mx-auto max-w-7xl px-4 pt-16 pb-24 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:max-w-none">
            
            <div className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
            
  
              <div className="mt-10 lg:mt-0">
                <h2 className="text-lg font-medium text-gray-900">QuickPayment <span style={{color:"red"}}>Ensure the right name of Domestic Worker </span></h2>
  
                <div className="mt-4 rounded-lg border border-gray-800 bg-white shadow-sm">

                <div className="p-6">
                  <label htmlFor="paymentAmount" className="block text-sm font-medium text-gray-700">
                      Payment Amount
                  </label>
                  <input
                      type="text"
                      name="amount"
                      id="paymentAmount"
                      placeholder="payment amount"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-green-700 rounded-md border-2 focus:outline-none"
                      style={{ height: '2rem', backgroundColor: 'white' }}
                      value={paymentAmount}
                      onChange={(e) => setPaymentAmount(e.target.value)}
                  />
              </div>

            <div className="p-6">
                    <label htmlFor="maidName" className="block text-sm font-medium text-gray-700">
                            Select Maid
                        </label>

                  <VirtualizedSelect
                    name="maidFromErp"
                    id="maidName"
                    options={maidsList.map((maid) => ({ value: maid.name, label: maid.name }))}
                    value={{ value: selectedMaid, label: selectedMaid }}
                    onChange={(selectedOption) => setSelectedMaid(selectedOption?.value)}
                    placeholder="Select a maid..."
                    />

             </div>

                  <div className="p-6">
                          <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                    {orderLoading ? (
                      <LoadingComponent />
                    ) : (
                      <button
                        onClick={placeOrderHandler}
                        className="w-full rounded-md border border-transparent bg-indigo-600 py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                      >
                        Confirm Payment AED {paymentAmount}
                      </button>
                    )}
                  </div>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
