import React, { createContext, useState, useContext, useEffect } from 'react';
import * as jwt_decode from 'jwt-decode';



const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isLoggedIn: false,
    userName: '',
    token: null,
  });

  const validateToken = (token) => {
    try {
      const decodedToken = jwt_decode(token);
      const currentTime = Date.now() / 1000;

      return decodedToken.exp > currentTime;
    } catch {
      return false;
    }
  };

  useEffect(() => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      const parsedUserInfo = JSON.parse(userInfo);
      setAuthState({
        isLoggedIn: true,
        userName: parsedUserInfo.name, // Adjust according to your stored data structure
        token: parsedUserInfo.token,
      });
    }
  }, []);

  const login = (name, token) => {
    setAuthState({
      isLoggedIn: true,
      userName: name,
      token: token,
    });
    // Optionally save the token to localStorage or cookies here if needed
    localStorage.setItem('token', token);
  };

  const logout = () => {
    setAuthState({
      isLoggedIn: false,
      userName: '',
      token: null,
    });
    // Optionally clear the token from localStorage or cookies here
    localStorage.removeItem('token');
  };

  return (
    <AuthContext.Provider value={{ ...authState, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
