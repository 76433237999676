import React, { useState, useEffect } from "react";

import MaidsNationalityList from "./MaidsNationalityList ";
import Hcprovide from "./static/Hcprovide";
import theme from "../ui/theme/theme";
import Box from "@mui/material/Box";

export default function Allmaids({ data }) {
  const [showFilipino, setShowFilipino] = useState(true);
  const [showIndo, setShowIndo] = useState(false);
  const [showEthiopia, setShowEthiopia] = useState(false);
  const [showMyanmar, setShowMyanmar] = useState(false);
  const [showNebalAnnIndiaSerlinka, setShowNebalAnnIndiaSerlinka] =
    useState(false);
  const [showGhanaKenyaTanzania, setShowGhanaKenyaTanzania] = useState(false);
  const [setSelectedMaid] = useState(null);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Define your mobile breakpoint here
  const isMobileView = windowWidth <= 700;

  const handleButtonToggle = (setShowFunction) => {
    setShowFilipino(false);
    setShowIndo(false);
    setShowEthiopia(false);
    setShowMyanmar(false);
    setShowNebalAnnIndiaSerlinka(false);
    setShowGhanaKenyaTanzania(false);
    setShowFunction(prevState => !prevState);
  };

  const filterMaids = (nationalityCriteria) => {
    return data.filter(maid => {
      const nationality = maid.nationality || ""; // Default to an empty string if null
      const maidBooked = maid.maid_booked || "";
      const visaStatus = maid.visa_status || "";
  
      return (
        nationalityCriteria.some(criterion => nationality.includes(criterion)) &&
        !maidBooked.includes("Booked") &&
        !maidBooked.includes("Empty") &&
        !maidBooked.includes("Hold") &&
        !visaStatus.includes("Visa")
      );
    });
  };
  


  // Define your maids filters based on nationality here
  const filipinoMaids = filterMaids(["Philippines"]);
  const indonesianMaids = filterMaids(["Indonesia"]);
  const ethiopianMaids = filterMaids(["Ethiopia"]);
  const myanmarMaids = filterMaids(["Myanmar"]);
  const nebalAnnIndiaSerlinkaMaids = filterMaids(["Nepal", "Sri_Lanka", "Indian"]);
  const ghanaKenyaTanzaniaMaids = filterMaids(["Ghana", "Kenya", "Uganda", "Tanzania"]);

 
  const renderNationalityButton = (nationality, count, showState, setShowFunction) => {
    if (count > 0) {
      return (
        <div
          key={nationality}
          className={`nationality-button ${showState ? 'active' : ''}`}
          onClick={() => handleButtonToggle(setShowFunction)}
        >
          {nationality}
          <div className="circle">
            {count}
          </div>
        </div>
      );
    }
    return null;
  };
  
  


// Helper function to render the MaidsNationalityList based on the state
const renderMaidsList = () => {
  if (showFilipino) {
    return <MaidsNationalityList data={filipinoMaids} handleMaidClick={setSelectedMaid} />;
  } else if (showIndo) {
    return <MaidsNationalityList data={indonesianMaids} handleMaidClick={setSelectedMaid} />;
  } else if (showEthiopia) {
    return <MaidsNationalityList data={ethiopianMaids} handleMaidClick={setSelectedMaid} />;
  } else if (showMyanmar) {
    return <MaidsNationalityList data={myanmarMaids} handleMaidClick={setSelectedMaid} />;
  } else if (showNebalAnnIndiaSerlinka) {
    return <MaidsNationalityList data={nebalAnnIndiaSerlinkaMaids} handleMaidClick={setSelectedMaid} />;
  } else if (showGhanaKenyaTanzania) {
    return <MaidsNationalityList data={ghanaKenyaTanzaniaMaids} handleMaidClick={setSelectedMaid} />;
  } else {
    return <p>Select Nationality.</p>;
  }
};

  return (
    <div>
      <div
        className="bg-white rounded-lg w-full mx-auto my-5 md:my-10 p-5 container"
        style={{
          maxWidth: "1000px",
          marginBottom: "7px",
        }}
      >
        <h1
          style={{
            fontSize: "30px",
            fontWeight: "bold",
            textTransform: "capitalize",
            marginBottom: "20px",
          }}
        >
          Choose your
          <span style={{ color: theme.palette.primary.main }}> Maid</span>
        </h1>
        <Box
          className="mb-4 flex flex-wrap"
          sx={{
            borderRadius: "80px",
            boxShadow: "rgb(187, 187, 187) 0px 4px 17px -2px",
            justifyContent: "center",
            padding: '16px',
            justifyContent: { xs: "center", md: "space-between" },
            '@media (max-width: 768px)': {
              borderRadius: "30px",
            },
          }}
        >
          {renderNationalityButton("Filipina", filterMaids(["Philippines"]).length, showFilipino, setShowFilipino)}
          {renderNationalityButton("Ethiopia", filterMaids(["Ethiopia"]).length, showEthiopia, setShowEthiopia)}
          {renderNationalityButton("Indonesian", filterMaids(["Indonesia"]).length, showIndo, setShowIndo)}
          {renderNationalityButton("Myanmar", filterMaids(["Myanmar"]).length, showMyanmar, setShowMyanmar)}
          {renderNationalityButton("Others", filterMaids(["Nepal", "Sri_Lanka", "Indian"]).length, showNebalAnnIndiaSerlinka, setShowNebalAnnIndiaSerlinka)}
          {renderNationalityButton("Africa", filterMaids(["Ghana", "Kenya", "Uganda", "Tanzania"]).length, showGhanaKenyaTanzania, setShowGhanaKenyaTanzania)}
        </Box>

        <div className="md:ml-10 mt-4 md:mt-0">
          <p className="font-stylish text-lg">Monthly Contract</p>
          <img
            src="/signature.svg"
            alt="Signature"
            className="signature-image"
          />
        </div>

        <div style={{ marginBottom: "10px" }}>
          <Hcprovide />
        </div>
      </div>
      <div className={isMobileView ? "maid_list_mobile" : "maid_list_desktop"}>
        {renderMaidsList()}
      </div>
   
    </div>
  );
}
