// src/components/AboutUs.js
import React from 'react';
import AboutusDescription from './AboutusDescription';
import OurServices from './OurServices';
import MaidsExperience from './MaidsExperience';
import Statistics from './Statistics';
import { Helmet } from 'react-helmet-async'; // Import Helmet

const AboutUs = () => {
  return (
    <div className="pb-10" >
      <Helmet>
        <title>About Homecare </title>
        <meta name="description" 
          content="Homecare is your one stop destination to find a solution to all your domestic workers visa services requirements , hiring full-time maids, Sign &amp; Pay online in 5 minutes." 
          data-rh="true"
        />
        <link rel='canonical' href='/about_us' />
      </Helmet>
      <AboutusDescription />
      <OurServices />
      <MaidsExperience />
      <Statistics /> 
    </div>
  );
};

export default AboutUs;
