// theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#186ffa",
    },
    secondary: {
      main: "#faa318",
    },
    gray: {
      main: "#f7faff",
    },
  },

  typography: {
    fontFamily: "Roboto, sans-serif",
  },
  overrides: {
    MuiButton: {
      root: {
        backgroundColor: "red !important",
        color: "white",
        textTransform: "lowercase",
        transitions: {
          easing: "ease-in-out",
          duration: "0.2s",
        },
      },
    },
  },
  transitions: {
    easing: "ease-in-out",
    duration: "0.2s",
  },
});

export default theme;
