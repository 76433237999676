import * as React from "react";

import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import theme from "../ui/theme/theme";

export const NavBarItem = (itemProps) => {
  return (
    <Link to={itemProps.url} key={itemProps.url}>
      <Typography
        component="p"
        sx={{
          mr: "40px",
          color: "#646464",
          ":hover": {
            color: ` ${theme.palette.secondary.main} !important`,
          },
          "& .MuiSvgIcon-root": { fontSize: "20px", mr: "5px" },
          mt: "7px",
        }}
      >
        {itemProps.icon}
        {itemProps.tabName}
      </Typography>
    </Link>
  );
};

export default NavBarItem;
