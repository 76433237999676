import React, { useState } from 'react';

const StatisticCard = ({ number, label, bgColor }) => (
  <div className="flex flex-col items-center justify-center p-6 m-4 bg-white bg-opacity-75 rounded-lg shadow">
    <div className={`w-24 h-24 rounded-full flex items-center justify-center ${bgColor}`}>
      <span className="text-3xl font-bold text-white">{number}</span>
    </div>
    <span className="mt-4 text-xl font-bold text-gray-600">{label}</span>
  </div>
);

const statsData = [
  { number: '100+', label: 'Professional Team', bgColor: 'bg-blue-400' },
  { number: '10+', label: 'Years of Experience', bgColor: 'bg-pink-400' },
  { number: '50K', label: 'Happy Clients', bgColor: 'bg-purple-400' }
];

const Statistics = () => {
  const [backgroundImageSrc, setBackgroundImageSrc] = useState('aboutUs/images/Statistics.jpg');
  const [imageLoaded, setImageLoaded] = useState(true);

  const handleImageError = () => {
    setImageLoaded(false);
  };

  return (
    <div className="container mx-auto relative text-center py-12 rounded-lg overflow-hidden" aria-label="Statistics Background">
      <img 
        src={backgroundImageSrc} 
        alt="Statistics Background"
        className="hidden" 
        onError={handleImageError}
      />
      <div 
        className="absolute inset-0" 
        style={{ backgroundImage: `url(${imageLoaded ? backgroundImageSrc : ''})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
      >
        <div className="absolute inset-0 bg-sky-700 bg-opacity-50"></div>
      </div>
      {!imageLoaded && (
        <span className="sr-only">Statistics Background not available</span>
      )}
      <div className="relative z-10">
        <h2 className="text-5xl text-white font-bold mb-8">We Provide with</h2>
        <div className="flex justify-center items-center flex-wrap">
          {statsData.map(stat => (
            <StatisticCard key={stat.label} number={stat.number} label={stat.label} bgColor={stat.bgColor} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Statistics;
