import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Package4Maids from "./hooks/Package4Maids";
import Login from "./users/forms/login";
import PrivacyPolicy from "./components/Privacy Policy/PrivacyPolicy";
import Mainpage from "./mainpage/Mainpage";
import UserProfile from "./users/profile/UserProfile";
import NavBar from "./Navbar/Navbar";
import AboutUs from "./components/AboutUs/AboutUs"
import Footer from "./footer/Footer";
import QuickPayment from "./users/quickpayment/QuickPaymentsUser";
import GetVisa from "./users/forms/get-visa";
import VisaThankYouMessage from "./components/ThankMessage/VisaThankYouMessage";
import PaymentThankYouMessage from "./components/ThankMessage/PaymentThankYouMessage";
import MaidDetails from "./users/forms/maid-details";
import GuestPayment from "./users/forms/guest-payment";
import { AuthProvider } from './contexts/AuthContext';
import usePageViews from './hooks/usePageViews'; // Import the hook


// Define a component that will use the usePageViews hook
const PageTracker = () => {
  usePageViews();
  return null; // This component doesn't render anything
};


function App() {
  useEffect(() => {
    window.Intercom("boot", {
      app_id: "rtua6vqx", // Replace with your actual Intercom App ID
      // Add other configurations or user info if needed
    });

    return () => {
      window.Intercom("shutdown"); // Properly shutdown Intercom on unmount
    };
  }, []);

  return (
    <AuthProvider> {/* Wrap your components with AuthProvider */}
      <Router>
      <PageTracker /> {/* This component will call usePageViews */}
        <div className="bg-gray-100 min-h-screen font-sans" style={{ backgroundColor: "#f7faff" }}>
          <NavBar />
          <div style={{ paddingTop: "108px" }}>
            <Routes>
              <Route path="/" element={<Mainpage />} />
              <Route path="/about_us" element={<AboutUs />} />
              <Route path="/package4" element={<Package4Maids />} />
              <Route path="/login" element={<Login />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/customer-profile" element={<UserProfile />} />
              <Route path="/quickpayment" element={<QuickPayment />} />
              {/* <Route path="/otp" element={<OtpVerifyPhoneNumber />} /> */}
              <Route path="/get-visa" element={<GetVisa />} />
              <Route path="/visa-thank-you" element={<VisaThankYouMessage />} />
              <Route path="/payment-thank-you" element={<PaymentThankYouMessage />} />
              <Route path="/maid-details" element={<MaidDetails />} />
              <Route path="/guest-payment" element={<GuestPayment />} />
            </Routes>
          </div>
        </div>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default App;
