export enum PATHS {
  HOME = "/",
  ABOUT_US = "/about_us",
  REGISTER = "/register",
  LOGIN = "/login",
  PRIVACY_POLICYE = "/privacy-policy",
  SERVICES = "/Services",
  CUSTOMERS_PROFILE = "/customer-profile",
  PACKAGES = "/package4",
  GET_VISA = "/get-visa",
}
