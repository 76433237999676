import React, { useState } from 'react';

const FeatureCard = ({ image, title, description }) => {
    const [loadError, setLoadError] = useState(false);

    const handleImageError = () => {
        setLoadError(true);
    };

    return (
        <div className="max-w-sm w-full md:w-1/3 p-4">
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                {!loadError ? (
                    <img className="w-full" src={image} alt={title} onError={handleImageError} />
                ) : (
                    <div className="text-center text-gray-700 p-4">Image not found</div>
                )}
                <div className="p-4">
                    <div className="font-bold text-xl mb-2">{title}</div>
                    <p className="text-gray-700 text-base">{description}</p>
                </div>
            </div>
        </div>
    );
};

const MaidsExperience = () => {
    // Paths relative to the public directory
    const feature1Image = '/aboutUs/images/coocking.jpg';
    const feature2Image = '/aboutUs/images/baby.jpg';
    const feature3Image = '/aboutUs/images/iron.jpg';
    // const feature4Image = '/aboutUs/images/Homecare.jpeg';

    return (
        <div className="container mx-auto py-8 px-6">
            <h1 className="text-4xl font-semibold mb-4 text-blue-700 text-center" style={{ fontFamily: "'Your Custom Font', sans-serif" }}>Professional Maids</h1>
            <div className="flex flex-wrap -mx-4">
                <FeatureCard 
                    image={feature1Image}
                    title="Cooking"
                    description="We provide our maids, who have previous experience in cooking or are willing to learn, with a cooking training course for 46 hours to improve, empower, and prepare them for work in the UAE market."
                />
                <FeatureCard 
                    image={feature2Image}
                    title="BabySitting"
                    description="We provide our maids, who have previous experience in babysitting or are willing to learn, with a babysitting training course for 16 hours to improve, empower, and prepare them for work."
                />
                <FeatureCard 
                    image={feature3Image}
                    title="Cleaning"
                    description="We provide our maids, the first-timers or those who have previous experience in cleaning, with a 36-hour housekeeping training course to improve and prepare them for work in the UAE market."
                />
                 {/* <FeatureCard 
                    image={feature4Image}
                    title="On-Time Delivery"
                    description="We adhere to project timelines strictly to ensure on-time delivery."
                /> */}
            </div>
        </div>
    );
};

export default MaidsExperience;
