import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import theme from "../ui/theme/theme";
import ServiceItem from "../components/service-item";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import { PATHS } from "../constants/routes.ts";
import React, { useEffect, useState } from 'react';

export default function OurServices() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

     // Function to update the screen width
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  // Use useEffect to add the resize event listener when the component mounts
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Clean-up function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  const servicesArray = [
    {
      title: "test 1",
      text: "      Sequi aliquid voluptate, aperiam sit nobis quidem nihil impedittotam. Dicta est distinctio minus incidunt. Maiores doloribus itaquepraesentium, officia excepturi nulla?",
      url: "/",
      img: "/images/our-service/ourservice-1.png",
    },
   
  ];
  return (
    <Box
      sx={{
        // background: "repeating-linear-gradient(0deg, #0050d0, #186ffa)",
        background: "url(/images/services-bg.png)",
        pt: { xs: "220px", md: "300px" },
        pb: "30px",
        px: { xs: "10px", md: "40px" },

        backgroundSize: "cover",
        mt: { xs: "-80px", md: 0 },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
        flexWrap="wrap"
      >
        <Typography
          variant="h2"
          sx={{
            fontWeight: "bold",
            textTransform: "uppercase",
            letterSpacing: "1px",
            fontSize: { xs: "35px", md: "60px" },
            color: theme.palette.common.white,
            "& span": { color: theme.palette.secondary.main },
          }}
        >
          What Our
          <span> Customers Said</span>
        </Typography>
        <Box   sx={screenWidth < 768 ? {
                 position: 'fixed',
                 bottom: 0,
                 left: -18,
                 width: '100%',               
                 backgroundColor: 'rgba(124, 147, 183, 0.8)', 
                 zIndex: 1000, 
               
                 px: { xs: '20px', md: '50px' },
                 py: '7px', 
                 display: 'flex',
                 justifyContent: 'center', 
                 alignItems: 'center'
              } : {
                display: "flex", 
                flexDirection: "row", 
                flexWrap: "wrap"
              }}>
          <Link to={PATHS.PACKAGES}>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                px: { xs: "10px", md: "30px" },
                borderRadius: "35px",
                fontSize: { xs: "16px", md: "21px" },
                color: theme.palette.common.white,
                mr: { xs: "10px", md: "30px" },
                mt: { xs: "20px", md: 0 },
                height: { xs: "42px", md: "63px" },

                // background: "repeating-linear-gradient(90deg, #d68400,#faa318)",
                // transitions: ".2s ease-in-out",
              }}
            >
              <Box
                component="img"
                src="/icons/our-services/our-services-icon-1.png"
                alt="our-services-icon-1"
                sx={{
                  width: { xs: "30px", md: "45px" },
                  height: { xs: "30px", md: "45px" },
                  objectFit: "contain",
                  mr: { xs: "10px", md: "25px" },
                }}
              />
              Get A Maid
            </Button>
          </Link>
          <Link to={PATHS.GET_VISA}>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                px: { xs: "10px", md: "30px" },
                borderRadius: "35px",
                fontSize: { xs: "16px", md: "21px" },
                color: "#939597",
                background: theme.palette.common.white,
                border: "5px solid #ededed",
                mt: { xs: "20px", md: 0 },
                height: { xs: "42px", md: "63px" },
              }}
            >
              <Box
                component="img"
                src="/icons/our-services/our-services-icon-2.png"
                alt="our-services-icon-2"
                sx={{
                  width: { xs: "30px", md: "45px" },
                  height: { xs: "30px", md: "45px" },
                  objectFit: "contain",
                  mr: { xs: "10px", md: "25px" },
                }}
              />
              Get A Visa
            </Button>{" "}
          </Link>
        </Box>
      </Stack>
      <Box
        component="hr"
        height="2px"
        backgroundColor="#bbbb"
        width="80%"
        margin="auto"
        sx={{ my: { xs: "20px", md: "40px" } }}
      />
      <Box
        sx={{
          "& .swiper-pagination-bullet": {
            width: "7px",
            height: "7px",
            background: "#cfd4d8",
            backdropFilter: " blur(5px)",
            borderRadius: "100%",
            mx: "6px",

            cursor: "pointer",
          },
          "& .swiper-pagination-bullet-active": {
            background: theme.palette.secondary.main,
            width: "8px",
            height: "8px",
          },

          "& .swiper-pagination": {
            display: "flex",
            justifyContent: "center",
            mt: "24px",
            mb: "16px",
            position: "static",
            alignItems: "center",
          },
          "& .swiper-container": {
            height: "300px" /* Set a height or adjust as needed */,
          },
        }}
      >

      {/* <Swiper
          spaceBetween={0}
          slidesPerView={1}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          centeredSlides
          slideToClickedSlide
          loop={true}
          coverflowEffect={{
            depth: 0,
            rotate: 0,
            scale: 0.8,
            slideShadows: true,
          }}
          speed={600}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {servicesArray.map((item, index) => (
            <SwiperSlide key={index}>
              {(
                <ServiceItem
                  title={item.title}
                  text={item.text}
                  url={item.url}
                  img={item.img}
                />
              )} 
              <iframe src='https://2631f8bea52c4d5793aff24d00cb81ca.elf.site' width='100%' height='1000' frameborder='0'></iframe>
            </SwiperSlide>
          ))}
        </Swiper> */}

        <iframe src='https://2631f8bea52c4d5793aff24d00cb81ca.elf.site' width='100%' height='500' frameborder='0'></iframe>
      </Box>
    </Box>
  );
}