import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Stack, TextField, Button, InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useAuth } from '../contexts/AuthContext';
import { sendOtpAction, verifyTheOtpAction, resendOtpAction } from "../redux/slices/users/usersSlice";
import LoadingComponent from "../hooks/LoadingComp/LoadingComponent";
import { useStore } from "../utils/store";

export const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedMaid } = useStore();
  const { login } = useAuth();
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  // Initialize timeLeft with null to indicate timer is not started
  const [timeLeft, setTimeLeft] = useState(null);
  const { loading } = useSelector((state) => state?.users?.userAuth);

  const cleanPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/\s+/g, '').trim();
  };

  const validatePhoneNumber = (phoneNumber) => {
    // Remove spaces and trim
    const cleanedPhone = cleanPhoneNumber(phoneNumber);
    return /^05\d{8}$/.test(cleanedPhone);
  };

  const handlePhoneChange = (e) => {
    const inputPhone = e.target.value;
    setPhone(inputPhone);
  
    // Validate phone number starts with '05' and overall format
    if (!inputPhone.startsWith("05")) {
      setPhoneError("Phone number must start with '05'");
    } else if (!validatePhoneNumber(inputPhone)) {
      setPhoneError("Invalid phone number format");
    } else {
      setPhoneError(""); // No error, clear any existing error messages
    }
  };

 

  useEffect(() => {
    let timerId;
    if (timeLeft > 0) {
      timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    } else if (timeLeft === 0) {
      // Timer has finished
      setTimeLeft(null);
    }
    return () => clearTimeout(timerId);
  }, [timeLeft]);



  const handleSendOtp = async (e) => {
    e.preventDefault();
    const cleanedPhone = cleanPhoneNumber(phone);
    if (!validatePhoneNumber(cleanedPhone)) {
      setErrorMessage('Invalid phone number format');
      return;
    }
    try {
      await dispatch(sendOtpAction({ phone: cleanedPhone })).unwrap();
      setOtpSent(true);
      setErrorMessage(""); // Clear previous error messages
      setTimeLeft(30); // Start the timer when OTP is sent
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(error?.message || "An error occurred");
    }
  };
  

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Reset any previous error messages
    const cleanedPhone = cleanPhoneNumber(phone);
    try {
      const response = await dispatch(verifyTheOtpAction({ phone: cleanedPhone, otp })).unwrap();

      if (response.status === "success") {
        // Use the login function from AuthContext
        login(response.userFound.fullname);

        // Redirect the user based on your application's needs
        if (!selectedMaid) {
          navigate('/'); // Replace with your success route
        } else {
          navigate('/maid-details'); // Replace with your success route
        }
      } else {
        // If the response does not indicate success
        setErrorMessage(response.message || "Verification failed. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setErrorMessage(error?.response?.data?.message || "Verification failed. Please try again.");
    }
  };
  

  const resendOtp = async () => {
    const cleanedPhone = cleanPhoneNumber(phone);
    try {
      await dispatch(resendOtpAction({ phone: cleanedPhone })).unwrap();
      console.log('OTP Sent Successfully');
      setTimeLeft(30); // Reset the timer
    } catch (error) {
      console.error("Error resending OTP:", error);
      setErrorMessage(error?.message || "Failed to resend OTP.");
    }
  };
  
  

  return (
    <Box
      component="form"
      sx={{ "& > :not(style)": { m: 1, width: "25ch" } }}
      noValidate
      autoComplete="off"
      onSubmit={otpSent ? handleVerifyOtp : handleSendOtp}
    >
      {errorMessage && <h3 className="text-red-500">{errorMessage}</h3>}
      <Stack  spacing={3} sx={{ width: { xs: "300px !important", md: "500px !important" }, ".MuiInputBase-root": { borderRadius: "30px", }, }} >
      <TextField
        id="login-PhoneNumber"
        fullWidth
        type="tel"
        label="Phone Number"
        placeholder="05xxxxxxxx"
        sx={{ borderRadius: "30px" }}
        name="phone"
        value={phone}
        onChange={handlePhoneChange}
        error={!!phoneError}
        helperText={phoneError}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LocalPhoneIcon />
            </InputAdornment>
          ),
        }}
      />


      {otpSent && (
        <TextField
          fullWidth
          type="tel" // Change type to 'tel' for mobile-friendly OTP input
          label="OTP"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
        />
      )}


      <div className="resend-otp-section" style={{ marginTop: "20px", textAlign: "center" }}>
        {otpSent && timeLeft > 0 ? (
          <p>You can resend OTP in {timeLeft} seconds</p>
        ) : otpSent && (
          <button 
            type="button" // This prevents the form from being submitted
            onClick={resendOtp}
            disabled={loading}
            style={{
              fontSize: "14px",
              textTransform: "capitalize",
              backgroundColor: "transparent",
              border: "none",
              color: "#007bff",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            Resend Code
          </button>
        )}
      </div>


        <div className="w-full px-4">
          {loading ? (
            <LoadingComponent />
          ) : (
            <Button
              id="login-button"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={{ borderRadius: "30px" }}
              disabled={!phone || !!phoneError || (otpSent && !otp)}
              onClick={!otpSent ? handleSendOtp : handleVerifyOtp}
            >
              {otpSent ? 'Verify OTP' : 'Send OTP'}
            </Button>
          )}
        </div>
      </Stack>
     
    </Box>
  );
};

export default SignIn;
