import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'; // Import Helmet

const PrivacyPolicy = () => {
  return (
    <div className="pb-10" >
      <Helmet>
        <title>Homecare's Privacy Policy</title>
        <meta name="description" 
          content="Homecare is your one stop destination to find a solution to all your domestic workers visa services requirements , hiring full-time maids, Sign &amp; Pay online in 5 minutes." 
          data-rh="true"
        />
        <link rel='canonical' href='/privacy-policy' />
      </Helmet>

      <div className="bg-white p-8 m-4 rounded-lg shadow-md max-w-screen-md mx-auto">
      <h1 className="text-3xl font-bold mb-4">How we use your personal information</h1>
      <p>Hello. We are Homecare. Here's how we protect your data and respect your privacy.</p>
      

      <section className="mt-6">
        <ul className="list-disc ml-6">
          <li className="text-l font-semibold mb-3"> The personal information we collect allows us to keep you posted on our latest announcements and upcoming services.</li>
          <li className="text-l font-semibold mb-3"> We also use personal information to help us create, develop, operate, deliver, and improve our services, content and advertising, and for anti-fraud purposes.</li>
          <li className="text-l font-semibold mb-3"> We may use your personal information, including date of birth, to verify identity, assist with identification of users, and to determine appropriate services.</li>
          <li className="text-l font-semibold mb-3"> From time to time, we may use your personal information to send important notices, such as communications about maids contracts and changes to our terms, conditions, and policies. Because this information is important to your interaction with us.</li>
          <li className="text-l font-semibold mb-3"> We may also use personal information for internal purposes such as auditing, data analysis, and to improve our services, and customer communications.</li>
        </ul>
      </section>
    
      {/* The link back to your application or home */}
        <Link to="/login" className="text-blue-600 hover:text-blue-500">Go Back</Link>
      </div>
        
    </div>
  );
};

export default PrivacyPolicy;
