import React, { useState } from 'react';
const OurServices = () => {
  const [imageAlt, setImageAlt] = useState('Service Image');
  const handleImageError = () => {
    setImageAlt('Image not found');
  };
    return (
      <div className="container mx-auto py-12 px-6">
      <div className="flex flex-col-reverse md:flex-row items-center">
        <div className="w-full md:w-1/2">
            <h2 className="text-2xl font-bold mb-4  text-sky-600">LET US DO THE WORK FOR YOU !</h2>
            <p className="mb-4">
              OUR TYPING SERVICES INCLUDE:
            </p>
            <ul className="list-none pl-0">
              {['Emirates ID Application Typing.',
                'Residency Stamping Application.',
                'MOHRE - Domestic Workers.',
                'Medical Application Typing.',
                'Health Insurance.',
                'Visa Application.',
                'Visa Cancellation.',
                'Visa Renewal.',
                'Change Status.'].map((item) => (
                  <li key={item} className="flex items-center mb-2">
                    <span className="inline-block h-2 w-2 mr-2 bg-blue-500 rounded-full"></span>
                    {item}
                  </li>
                ))
              }
            </ul>
          </div>
          <div className="w-full md:w-1/2 mb-4 md:mb-0">
            <img 
            src="aboutUs/images/typing.png" 
            alt={imageAlt} 
            className="max-w-xs md:max-w-md lg:max-w-lg xl:max-w-xl" 
            onError={handleImageError}
          />
          </div>
        </div>
      </div>
    );
  };
  
  export default OurServices;
