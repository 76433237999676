import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FooterSocialMediaLinks from "../../components/footer-Social-media-links";
import HomeCareCopyRight from "../../components/home-care-copy-right";
import { useTheme } from "@mui/material/styles";
import GuestPaymentForm from "../../components/guest-payment-form";
import qs from "query-string";
import { useLocation, useNavigate } from "react-router";
import { Helmet } from 'react-helmet-async'; // Import Helmet

const GuestPayment = () => {
  const { search, state } = useLocation();
  const params = qs.parse(search);
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
    <Helmet>
        <title id="title">Homecare Guest Payment</title>
        <meta name="description" 
          content="Sign &amp; Pay Online. We’ll handle the entire visa process and issue your maid’s Residency Visa. Avoid visits to medical centers &amp; typists."
        />
        <link rel='canonical' href='/guest-payment' />
      </Helmet>
      <Grid container spacing={0} sx={{ mt: 0 }}>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            background:
              "repeating-linear-gradient(0deg, #5a5a5a, #7d7e7e,#8a8a8a,#939597,#a2a2a2,#a5a5a5,#b2b2b2,#ababab)",
            px: { xs: "20px", md: "10px" },
          }}
        >
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="space-around"
            sx={{
              height: { xs: "calc(55vh - 116px)", md: "calc(100vh - 106px)" },
            }}
          >
            <Box
              component="img"
              src="/images/logo-white.png"
              alt="login Logo"
              sx={{
                width: { xs: "80px", md: "125px" },
                height: "auto",
              }}
            />
            <div>
              <Typography
                component="h1"
                variant="h1"
                color="initial"
                sx={{
                  color: theme.palette.common.white,
                  textTransform: "uppercase",
                  fontSize: { xs: "30px", md: "55px" },
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Payment for Guest user
              </Typography>
            </div>
            <Box sx={{ display: { xs: "none", md: "Block" } }}>
              <FooterSocialMediaLinks />

              <HomeCareCopyRight />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={7}>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ height: "100%" }}
          >
            <Box
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
              sx={{ width: "100%" }}
            >
              <GuestPaymentForm params={params} />
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default GuestPayment;
