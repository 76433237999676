import React, { useState, useEffect } from "react";
import Allmaids from "./AllMaids";
import "./Allmaids.css";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Helmet } from 'react-helmet-async'; // Import Helmet
export default function Package4Maids() {
  const [data, setData] = useState([]);
  const [maids] = useState("");

  useEffect(() => {
    Promise.all([
      fetch("https://homecaredxb.me/api/v1/Maids?api-key=8c44w8kcocsw8gg0c4scwg4oogc8go4sgso4ccso&status[]=approved"),
      fetch("https://fcerp.me/api/v1/Maids?api-key=8ggcccc4ogkc08k88480k8cok4kwsw4w8skoo4cg&status=approved")
      
    ])
    .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
    .then(([data1, data2]) => {
      const mergedData = [...data1.response, ...data2.response];
      setData(mergedData);
    })
    .catch(error => console.error("Error fetching data:", error));
  }, []);

  const filteredData = data.filter(
    (maid) =>
      maid.maid_type === "HC" && maid.name.toLowerCase().includes(maids.toLowerCase())
  );

  return (
    <>
     <Helmet>
        <title id="title">Homecare -  Get a Full-Time Maid or Maid Visa</title>
        <meta name="description" 
          content="Homecare is your one stop destination to find a solution to all your domestic workers visa services requirements , hiring full-time maids, Sign &amp; Pay online in 5 minutes." 
          data-rh="true"
        />
        <link rel='canonical' href='/package4' />
      </Helmet>
      {filteredData.length > 0 ? (
        <Allmaids data={filteredData} />
      ) : (
        <Box
          sx={{
            display: "flex",
            height: "66vh",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
