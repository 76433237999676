export default function PaymentDetails({ date, amount, description, status, paymentNumber, worker , type }) {
  // This function determines the color class based on the payment status
  const getStatusColor = (status) => {
    const normalizedStatus = status ? status.toLowerCase() : '';
    switch (normalizedStatus) {
      case 'not paid':
        return 'text-red-600';
      case 'paid':
        return 'text-green-600';
      default:
        return 'text-gray-600';
    }
  };
  
  const paymentTitle = type === 'monthly' ? 'Monthly Payment' : 'Quick Payment';
  console.log(type);
  return (
    <div className="bg-white rounded-lg shadow-lg p-4 mb-4">
      <div className="mb-3">
        <h1 className={`text-xl font-semibold text-gray-800 mb-2`}>{paymentTitle}</h1>
        <p className="text-gray-600">Voucher Type: {description}</p>
        <p className="text-gray-600">Payment Number: {paymentNumber}</p>
        <p className="text-gray-600">For Worker: {worker}</p>
      </div>
      <div className="flex justify-between items-center flex-wrap">
        <div className="text-center mb-4 p-4">
          <h2 className="text-lg font-semibold text-gray-800">Total Amount:</h2>
          <p className="text-lg font-semibold">AED {amount}</p>
        </div>
        <div className="text-center mb-4 p-4">
          <h2 className="text-lg font-semibold text-gray-800">Status:</h2>
          <p className={`text-lg font-semibold ${getStatusColor(status)}`}>{status}</p>
        </div>
        <div className="text-center mb-4 p-4">
          <h2 className="text-lg font-semibold text-gray-800">Date:</h2>
          <p className="text-lg font-semibold">{date}</p>
        </div>
     </div>
      <div className="text-center text-gray-600 text-sm mt-3">
        Thank you for your business!
      </div>
    </div>
  );
}
