import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FooterSocialMediaLinks from "../../components/footer-Social-media-links";
import HomeCareCopyRight from "../../components/home-care-copy-right";
import { Helmet } from 'react-helmet-async'; // Import Helmet
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import GetVisaForm from "../../components/get-visa-form";
import Button from "@mui/material/Button"; // Import MUI Button
import WhatsAppIcon from "@mui/icons-material/WhatsApp"; // Import WhatsApp Icon

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const GetVisa = () => {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  return (
    <>
      <Helmet>
        <title id="title">Homecare - Apply or Renew your Maid's Visa Online in 5 min</title>
        <meta
          name="description"
          content="Sign & Pay Online. We’ll handle the entire visa process and issue your maid’s Residency Visa. Avoid visits to medical centers & typists."
        />
        <link rel="canonical" href="/get-visa" />
      </Helmet>
      <Grid container spacing={0} sx={{ overflowY: 'auto', minHeight: 'calc(100vh - 56px)' }}>
        {/* Left Section */}
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            background: "repeating-linear-gradient(0deg, #5a5a5a, #7d7e7e, #8a8a8a, #939597, #a2a2a2, #a5a5a5, #b2b2b2, #ababab)",
            px: { xs: 2, md: 4 },
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              overflowY: 'auto',
              flexGrow: 1,
            }}
          >
            <Box
              component="img"
              src="/images/logo-white.png"
              alt="Home Care Logo"
              sx={{
                width: { xs: '130px', sm: '180px', md: '210px' },
                height: 'auto',
                my: 2,
              }}
            />
            <Typography variant="h2" component="h2" sx={{
              fontWeight: 'bold',
              fontSize: { xs: '24px', sm: '36px', md: '48px' },
              mb: { xs: 1, sm: 2 },
              textTransform: 'uppercase',
              textAlign: 'center',
              color: 'white',
            }}>
              Get a Visa
            </Typography>
            
            <FooterSocialMediaLinks />
            <HomeCareCopyRight />
          </Stack>
        </Grid>

        {/* Right Section */}
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            p: { xs: 2, md: 4 },
          }}
        >
          <Box sx={{ width: '100%', maxWidth: { sm: '500px' } }}>
            <Typography variant="h5" component="div" sx={{ mb: 3, textAlign: 'center' }}>
            
Get a maid visa for just 8,500 AED and 150 AED/month!”

            </Typography>
            <Box component="ul" sx={{
              listStyleType: 'disc',
              pl: { xs: 2, sm: 3 },
              textAlign: 'left',
              margin: '0 auto',
              display: 'inline-block',
              width: 'fit-content',
              maxWidth: '100%',
            }}>
              {[
                'Save Time, Save Money, Stress Free!',
                'No hidden fees, No Security deposit, with easy installment plans',
                'Fast 20-day processing',
                'All-inclusive services (Emirates ID, medical, insurance)',
                'Your maid remains under our visa – zero legal risk to you!”'
                
              ].map((item, index) => (
                <Typography key={index} component="li" sx={{ pb: 0.5, fontSize: { xs: '12px', sm: '14px', md: '16px' } }}>
                  {item}
                </Typography>
              ))}
            </Box>
            
            {/* WhatsApp Buttons */}
            <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Button
                variant="contained"
                color="success"
                startIcon={<WhatsAppIcon />}
                sx={{ fontSize: '18px', width: '100%', py: 2 }}
                href="https://api.whatsapp.com/send?phone=97148103800&text=Hi, I want to inquire about Maid visa service"
                target="_blank"
              >
                Get More Details
              </Button>
              
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default GetVisa;