import * as React from "react";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import theme from "../ui/theme/theme";
import { FaInstagram, FaFacebook, FaTiktok, FaYoutube } from 'react-icons/fa';

export const FooterSocialMediaLinks = () => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        py: { xs: "10px", md: "15px" },
        "& a div": {
          fontSize: "18px",
          width: "30px",
          height: "30px",
          padding: "7px",
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mx: "3px",
          color: theme.palette.common.white,
          ":hover": {
            transition: "ease-in-out .2s",
            transform: "translateY(-5px)",
            boxShadow: "2px 2px 4px 0px #fff",
          },
        },
      }}
    >
      <Link to="https://shorturl.at/FO156" target="_blank">
        <div>
          <FaInstagram />
        </div>
      </Link>
      <Link to="https://shorturl.at/bkY12" target="_blank">
        <div>
          <FaFacebook />
        </div>
      </Link>
      <Link to="https://shorturl.at/bdsvR" target="_blank">
        <div>
          <FaTiktok />
        </div>
      </Link>
      <Link to="https://shorturl.at/bhjlU" target="_blank">
        <div>
          <FaYoutube />
        </div>
      </Link>
    </Stack>
  );
};

export default FooterSocialMediaLinks;
