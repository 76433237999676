import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import baseURL from "../../../utils/baseURL";
import { useStore } from "../../../utils/store";


//initialState
const initialState = {
  loading: false,
  error: null,
  users: [],
  user: null,
  profile: {},
  userAuth: {
    loading: false,
    error: null,
    userInfo: localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null,
  },
};

//register action
export const registerUserAction = createAsyncThunk(
  "users/register",
  async (
    {fullname, phone ,email,address, recaptchaValue,termsAccepted},
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      // Check if the terms and conditions were accepted
      if (!termsAccepted) {
        throw new Error('You must accept the terms and conditions to register.');
      }
      //make the http request
      const { data } = await axios.post(`${baseURL}/users/register`, {
        phone,
        fullname,
        email,
        address,
        recaptchaValue ,
        termsAccepted 
      });
      // Save the user and token into local storage
      localStorage.setItem("userInfo", JSON.stringify(data));
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data || error.message);
    }
  }
);

// Add this action in your users slice
export const sendOtpAction = createAsyncThunk(
  "users/send-otp",
  async ({ phone }, { rejectWithValue }) => {
    try {
      // Replace with your actual API endpoint
      const { data } = await axios.post(`${baseURL}/users/send-otp`, { phone });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);




//verify the phone number
export const verifyTheOtpAction = createAsyncThunk(
  "users/verify-otp", // Action type prefix
  async ({ phone, otp }, { rejectWithValue }) => {
    try {
      // Make an HTTP POST request to verify the OTP
      const { data } = await axios.post(`${baseURL}/users/verify-otp`, { 
        phone, 
        otp 
      });
        
      //save the user into localstorage
      localStorage.setItem("userInfo", JSON.stringify(data));
       
      return data; 
    } catch (error) {
      // Log the error for debugging purposes
      console.log("OTP verify faild Please Try again later ",error);

      // Return the error response from the server
      // The error structure depends on how your API formats error responses
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const resendOtpAction = createAsyncThunk(
  "users/resend-otp",
  async ({ phone }, { rejectWithValue }) => { // Extract phone from the object
    try {
      const { data } = await axios.post(`${baseURL}/users/resend-otp`, { phone });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);



//user profile action
export const getUserProfileAction = createAsyncThunk(
  "users/profile-fetched",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      //get token
      const token = getState()?.users?.userAuth?.userInfo?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await axios.get(`${baseURL}/users/profile`, config);
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

//login action
export const loginUserAction = createAsyncThunk(
  "users/login",
  async ({ phone }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${baseURL}/users/login`, { phone });
      // Save to localStorage
      localStorage.setItem("userInfo", JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

//logout action
export const logoutAction = createAsyncThunk(
  "users/logout",
  async (payload, { dispatch }) => {
    // Reset selected maid in Zustand store
    useStore.getState().resetSelectedMaid();

    // Remove user info from localStorage
    localStorage.removeItem("userInfo");

    // Reset any other client-side state as necessary
    dispatch(resetUserState()); // If you have other user-specific states in Redux

    // No need to contact the backend if you are using token-based auth and storing tokens client-side
    return true;
  }
);




//users slice

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetUserState: () => initialState, // Reset to initial state
  },
  extraReducers: (builder) => {
    //handle actions
     // Login action
     builder.addCase(loginUserAction.pending, (state) => {
      state.userAuth.loading = true;
    })
    .addCase(loginUserAction.fulfilled, (state, action) => {
      state.userAuth.userInfo = action.payload;
      state.userAuth.loading = false;
    })
    .addCase(loginUserAction.rejected, (state, action) => {
      state.userAuth.error = action.payload;
      state.userAuth.loading = false;
    });

    //register
    builder.addCase(registerUserAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(registerUserAction.fulfilled, (state, action) => {
      state.user = action.payload;
      state.loading = false;
    });
    builder.addCase(registerUserAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    //logout
    builder.addCase(logoutAction.fulfilled, (state, action) => {
      state.userAuth.userInfo = null;
    });
    //profile
    builder.addCase(getUserProfileAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getUserProfileAction.fulfilled, (state, action) => {
      state.profile = action.payload;
      state.loading = false;
    });
    builder.addCase(getUserProfileAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });


   // OTP Verify action
   builder.addCase(verifyTheOtpAction.pending, (state) => {
    state.loading = true;
  })
  .addCase(verifyTheOtpAction.fulfilled, (state, action) => {
    state.userAuth.userInfo = action.payload;
    state.userAuth.loading = false;
  })
  .addCase(verifyTheOtpAction.rejected, (state, action) => {
    state.userAuth.error = action.payload;
    state.userAuth.loading = false;
  });

  // Send OTP action
  builder.addCase(sendOtpAction.pending, (state) => {
    state.loading = true;
  })
  .addCase(sendOtpAction.fulfilled, (state) => {
    state.loading = false;
  })
  .addCase(sendOtpAction.rejected, (state, action) => {
    state.error = action.payload;
    state.loading = false;
  });

// Resend OTP action
  builder.addCase(resendOtpAction.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(resendOtpAction.fulfilled, (state, action) => {
    state.loading = false;
    // Handle successful OTP resend
  });
  builder.addCase(resendOtpAction.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
  });


  
  },
});

export const { resetUserState } = usersSlice.actions;
//generate reducer
const usersReducer = usersSlice.reducer;

export default usersReducer;
