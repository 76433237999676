import * as React from "react";

import Typography from "@mui/material/Typography";
import theme from "../ui/theme/theme";
import Accordion from "@mui/material/Accordion";
import Box from "@mui/material/Box";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const Faq = () => {
  const accordionData =[
    {
      id: 1,
      title: "What are Tadbeer?",
      text: "TADBEER are service centers licensed by the Ministry of Human Resources and Emiratisation and they provide integrated services for those wishing to bring in and operate domestic workers, serving the national and resident families in accordance with the standards, controls and supervision of the ministry to achieve the convenience of dealers and get the best service.",
    },
    {
      id: 2,
      title: "Can my maid travel with me?",
      text: " Absolutely, your maid can travel with you freely.All she needs is a NOC provided for free upon request.",
    },
    {
      id: 3,
      title: "Where do you provide your services?",
      text: " Our services cover all across the UAE",
    },
    {
      id: 4,
      title: "What's your cancellation policy?",
      text: "You can cancel your contract any time you want, we won't be charging you any cancellation fee.",
    },
  ];
  return (
    <div>
      {accordionData.map((item) => (
        <Box
          sx={{
            "& .MuiPaper-root.Mui-expanded": {
              background: theme.palette.common.white,
              boxShadow: "1px 2px 9px 3px #bbb",
              mb: "15px !important",
            },
          }}
        >
          <Accordion
            key={item.title}
            sx={{
              background: "#eff5ff",
              borderRadius: "10px !important",
              border: "none",
              mb: "15px",
              boxShadow: "none",

              "::before": {
                display: "none",
              },
              "& .MuiAccordionSummary-content.Mui-expanded": {
                color: theme.palette.secondary.main,
                fontWeight: "700",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${item.id}a-content`}
              id={item.id}
            >
              <Typography>{item.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{item.text}</Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}
    </div>
  );
};

export default Faq;
