import axios from "axios";
import baseURL from "../../../utils/baseURL";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  orders: [],
  order: null,
  loading: false,
  error: null,
  successMessage: null,
  errorMessage: null,
  stats: null,
};

// Create visa request 
export const DirectHireVisaRequestAction = createAsyncThunk(
  "visa/request",
  async (
    { fullName, phoneNumber, email , recaptchaValue},
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      // Token
      const token = getState()?.users?.userAuth?.userInfo?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Request
      const { data } = await axios.post(
        `${baseURL}/visa/get/visa`,
        {
            fullName,
            phoneNumber,
            email,
            recaptchaValue,
        },
        config
      );
      
      dispatch(setSuccessMessage(data.message)); // Use the backend response message
      return data; // assuming your API returns some data
    } catch (error) {
    dispatch(setErrorMessage(error.message)); // Fix: use setErrorMessage here
      return rejectWithValue(error?.response?.data);
    }
  }
);

//register action
export const registerUserAction = createAsyncThunk(
  "users/register",
  async (
    { phone, fullname ,email,address, recaptchaValue},
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      //make the http request
      const { data } = await axios.post(`${baseURL}/users/register`, {
        
        phone,
        fullname,
        email,
        address,
        recaptchaValue 
      
      });

          // Save the user and token into local storage
          localStorage.setItem("userInfo", JSON.stringify(data));
     
      return data;
    } catch (error) {
         

      
      return rejectWithValue(error?.response?.data);
    }
  }
);




// Slice
const visaRequestSlice = createSlice({
    name: "dh_visa",
    initialState,
    reducers: {
      setSuccessMessage: (state, action) => {
        state.successMessage = action.payload;
        state.errorMessage = null; // Clear any existing error message
      },
      setErrorMessage: (state, action) => {
        state.errorMessage = action.payload;
        state.successMessage = null; // Clear any existing success message
      },
    },
    extraReducers: (builder) => {
      // VisaRequestAction
      builder.addCase(DirectHireVisaRequestAction.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(DirectHireVisaRequestAction.fulfilled, (state, action) => {
        state.loading = false;
        state.successMessage = action.payload.message; // Access the 'message' property
      });
      builder.addCase(DirectHireVisaRequestAction.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload.message || "Failed to submit the visa request"; // Access the 'message' property
      });
  },
});

// Generate the reducer
const visaRequestReducer = visaRequestSlice.reducer;

// Export actions
export const { setSuccessMessage, setErrorMessage } = visaRequestSlice.actions;

export default visaRequestReducer;
