import React from 'react';

const PrivacyPolicyCheckbox = ({ onCheckChange, isChecked, handleTermsLinkClick }) => {
  return (
    <div className="flex items-start">
      <input
        id="privacy-policy"
        name="privacyPolicy"
        type="checkbox"
        checked={isChecked}
        onChange={(e) => onCheckChange(e.target.checked)}
        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
      />
      <label htmlFor="privacy-policy" className="ml-2 block text-sm text-gray-900">
        I agree to the{' '}
        <a href="/privacy-policy" onClick={handleTermsLinkClick} className="text-blue-600 hover:text-blue-500">
          terms of use
        </a>
        {' '}by consentify *
      </label>
    </div>
  );
};

export default PrivacyPolicyCheckbox;
