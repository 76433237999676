import VideoMaid from "../ReusableComponent/VideoMaid";
import LoadingComponent from "../LoadingComp/LoadingComponent";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "../Allmaids.css";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import theme from "../../ui/theme/theme";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import Button from "@mui/material/Button";
import { useStore } from "../../utils/store";



const shareData = async (selectedMaid) => {
  const baseUrl = `${window.location.origin}/maid-details`;
 
  if (navigator.share) {
      try {
          await navigator.share({
              title: 'Hire this Maid',
              text: 'Check out this maid\'s details:',
              url: selectedMaid.name,
          });
          console.log('Content shared successfully');
      } catch (error) {
          console.error('Error sharing content', error);
          copyToClipboard(selectedMaid.name);
          alert("Link copied to clipboard. You can paste and share it manually.");
      }
  } else {
      // Fallback: Copy URL to clipboard
      copyToClipboard(selectedMaid.name);
      alert("Link copied to clipboard. You can paste and share it manually.");
  }
};

const copyToClipboard = (text) => {
  const el = document.createElement('textarea');
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};


const generateWhatsAppUrl = (maid) => {
  const message = `Hello, I am interested in learning more about ${maid.name}.`;
  const encodedMessage = encodeURIComponent(message);
  return `https://api.whatsapp.com/send?phone=%2B97148103800&text=${encodedMessage}`;
};

const ComputerViewForMaidsList = ({ data, isLoading, handleHireClick }) => {
  const selectMaid = useStore(state => state.selectMaid);
  const selectedMaid = useStore(state => state.selectedMaid);
 

  const openTheVideo = (maid) => {
    selectMaid(maid);
    // Additional logic for opening the video (if any)
  };

  const closeTheVideo = () => {
    selectMaid(null); // Deselect the maid when closing the video
    // Additional logic for closing the video (if any)
  };


  return (
    <div  style={{ marginBottom: "100px", width: "100%", marginTop: "50px" }}>
      {data.map((maid, i) => (
        <Box
          key={i}
          className="button-container  duration-500 ease-in-out" 
          style={{
            marginBottom: "25px",
            width: "100%",
            maxWidth: "1000px",
        
          
            marginInline: "auto",
          }}
        >
          <Grid
            container
            spacing={2}
          
            sx={{
              backgroundColor: "white",
              width: "100%",
              alignItems: "center",
              // padding: "20px",
              borderRadius: "0px",
              boxSizing: "unset",
              // pl: { xs: "0px" },

              mx: "auto",
              display: "flex",
              flexWrap: "wrap",
            }}
            onClick={() => openTheVideo(maid)}
          >
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                backgroundColor: "white",
                position: "relative",
                p: "10px !important",
              }}
            >
              <img
               
                src={maid.img}
                alt={maid.name}
                className=" "
                style={{
                  width: "50%",
                  minWidth: "90%",
                  height: "190px",
                  objectFit: "scale-down",
                  background: "white",
                  border: "1px solid gray",
                  borderRadius: "15px",
                  boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.99)",
                  display: "block",      // Center horizontally
                  margin: "0 auto",      // Center horizontally
                  verticalAlign: "middle",
                }}
              />

              <div
                className="play-button"
                style={{
                  position: "absolute",
                  left: " calc(15% - 19px)",
                  top: " calc(80% - 15px)",
                  background: "rgb(0 0 0 / 36%)",
                  transform: "scale(1)",
                  border: "1px solid white",
                
                }}
              >
                <div className="play-icon"></div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              sx={{ width: "100%", p: "20px !important" }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <h2
                  className="text-xl font-semibold"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "15px",
                    textTransform: "capitalize",
                    fontWeight: "bold",
                    marginBottom: "10px",
                  }}
                >
                  {maid.name}
                </h2>
                <Box
                  sx={{
                    display: "flex",
                    a: {
                      display: "flex",
                    },
                    svg: {
                      width: " 28px",
                      height: " 28px",
                      fontSize: " 23px",

                      display: " flex",
                      justifyContent: " center",
                      alignItems: " center",
                      borderRadius: " 100%",
                      p: "5px",
                      color: theme.palette.common.white,
                      ml: "5px",
                      ":hover": {
                        transform: "scale(1.2)",
                        transition: "ease-in-out .2s",
                      },
                    },
                  }}
                >
                  <a
                    href={generateWhatsAppUrl(maid)}
                    onClick={(e) => e.stopPropagation()}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginLeft: "auto" }}
                  >
                    <WhatsAppIcon sx={{ background: "#60d668" }} />
                  </a>

                  <a href="#" onClick={(e) => { e.preventDefault(); e.stopPropagation(); shareData(maid); }} style={{ marginLeft: 'auto' }}>
                      <ShareIcon sx={{ background: '#0050d0' }} />
                  </a>


                </Box>
              </Box>
              <Box
                sx={{
                  "& p": {
                    background: "#bbb6 !important",
                    color: theme.palette.primary.main,
                    fontSize: "13px",
                    display: "table",
                    marginBottom: "10px",
                    paddingInline: "10px",
                  },
                }}
              >
                <p className="text-gray-600 ">
                  {" "}
                  <span style={{ fontWeight: "bold" }}>Nationality:</span>{" "}
                  {maid.nationality}
                </p>
                <p className="text-gray-600">
                  <span style={{ fontWeight: "bold" }}> Age: </span>
                  {maid.age}
                </p>
                <p className="text-gray-600">
                  <span style={{ fontWeight: "bold" }}>Country-Exp:</span>{" "}
                  {maid.exp_country.length ===0 ? " First time" :  maid.exp_country.join(' , ')}
                </p>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ))}

      {selectedMaid && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-70">
          <div className="relative max-w-full">
            <Box
              sx={{
                marginTop: "50px",
                overflow: "auto",
                maxHeight: { xs: "calc(100vh - 135px)" },
              }}
              className="bg-white p-5 rounded-lg shadow-md w-80vw   fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 animate-fade-in"
            >
              <Button
                variant="contained"
                color="primary"
                onClick={closeTheVideo}
                sx={{
                  width: "40px",
                  minWidth: "40px",
                  height: "40px",
                  borderRadius: "100%",
                  padding: "0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "1px 1px 5px 0px #bbbb",
                  background: "#ff0000",
                  mb: "20px",
                  mx: "auto",
                }}
              >
                <CloseIcon />
              </Button>
              {selectedMaid?.video_link ? (
                <VideoMaid   video={selectedMaid?.video_link} />
              ) : (
                <Box
                  sx={{ height: "300px", width: "100%", background: "#bbbb" }}
                ></Box>
              )}

              <div style={{}} className="text-center mt-4">
                {isLoading ? (
                  <LoadingComponent />
                ) : (
                  <>
                    <Box
                      item
                      xs={12}
                      md={8}
                      sx={{ width: "100%", p: "20px !important" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                <h2
                          className="flex-wrap"
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontSize: "15px",
                            textTransform: "capitalize",
                            fontWeight: "bold",
                            marginBottom: "10px",
                          }}
                        >
                          {selectedMaid.name.length > 23 ? `${selectedMaid.name.substring(0, 23)}...` : selectedMaid.name}
                        </h2>

                        <Box
                          sx={{
                            display: "flex",
                            a: {
                              display: "flex",
                            },
                            svg: {
                              width: " 28px",
                              height: " 28px",
                              fontSize: " 23px",

                              display: " flex",
                              justifyContent: " center",
                              alignItems: " center",
                              borderRadius: " 100%",
                              p: "5px",
                              color: theme.palette.common.white,
                              ml: "5px",
                              ":hover": {
                                transform: "scale(1.2)",
                                transition: "ease-in-out .2s",
                              },
                            },
                          }}
                        >
                          <a href={generateWhatsAppUrl(selectedMaid)} target="_blank" rel="noopener noreferrer" style={{ marginLeft: "auto" }}>
                                <WhatsAppIcon sx={{ background: "#60d668" }} />
                            </a>

                            <a href="#" onClick={(e) => { e.preventDefault(); e.stopPropagation(); shareData(selectedMaid); }} style={{ marginLeft: 'auto' }}>
                                <ShareIcon sx={{ background: '#0050d0' }} />
                            </a>


                        </Box>
                      </Box>
                      <Box
                        sx={{
                          "& p": {
                            background: "#bbb6 !important",
                            color: theme.palette.primary.main,
                            fontSize: "13px",
                            display: "table",
                            marginBottom: "10px",
                            paddingInline: "10px",
                          },
                        }}
                      >
                        <p className="text-gray-600 ">
                          {" "}
                          <span style={{ fontWeight: "bold" }}>
                            Nationality:
                          </span>{" "}
                          {selectedMaid.nationality}
                        </p>
                        <p className="text-gray-600">
                          <span style={{ fontWeight: "bold" }}> Age: </span>
                          {selectedMaid.age}
                        </p>
                        <p className="text-gray-600">
                          <span style={{ fontWeight: "bold" }}>
                            Country-Exp:
                          </span>{""}
                          {selectedMaid.exp_country}
                        </p>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleHireClick}
                          sx={{
                            textTransform: "capitalize",
                            mt: "30px",
                            borderRadius: "30px",
                          }}
                        >
                          Hire {selectedMaid.name} & Pay
                        </Button>
                      </Box>
                    </Box>
                    {/* 
                    <div className="flex flex-col items-center space-y-4">
                      <WhatsAppButton maid={selectedMaid} />
                      <button
                        className="text-white font-bold py-2 px-6 rounded-full bg-gradient-to-r from-orange-400 to-yellow-500 hover:shadow-xl transform hover:scale-105 transition duration-300 ease-in-out"
                        onClick={handleHireClick}
                      >
                        Hire {selectedMaid.name} & Pay
                      </button>
                      <MaidsDetails selectedMaid={selectedMaid} />
                    </div> */}
                  </>
                )}
              </div>
            </Box>
          </div>
        </div>
      )}
    </div>
  );
};

export default ComputerViewForMaidsList;
