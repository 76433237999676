import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AssistantItem from "../components/assistant-item";
import Map from "../components/map.jsx";
import "swiper/css";
import OurServices from "./our-services.jsx";
import FaqPage from "./faq-page.jsx";
import { Helmet } from 'react-helmet-async'; // Import Helmet
import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';





export default function Mainpage() {

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
 

  // Function to update the screen width
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  // Use useEffect to add the resize event listener when the component mounts
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Clean-up function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { isLoggedIn, userName } = useAuth();

  return (
    <div>
       <Helmet>
        <title>Tadbeer Homecare </title>
        <meta name="description" 
          content="Homecare is your one stop destination to find a solution to all your domestic workers visa services requirements , hiring full-time maids, Sign &amp; Pay online in 5 minutes." 
          data-rh="true"
        />
        <link rel='canonical' href='/' />
      </Helmet>
      {isLoggedIn ? (
        <div>Welcome back, {userName}!</div>
      ) : (
        <div></div>
      )}
      <Box sx={{ padding: { md: "10px", xs: "20px" } }}>
        <Grid container spacing={2} sx={{}}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: " flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{}}>
              <Typography
                variant="p"
                color="initial"
                sx={{
                  fontWeight: "bold",
                  color: "#939597",
                  textTransform: "uppercase",
                  fontSize: { xs: "30px", md: "40px" },
                }}
              >
                Choose your
              </Typography>
              <Typography
                variant="h1"
                color="initial"
                sx={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  fontSize: { xs: "50px", md: "60px" },
                }}
              >
                Assistant
              </Typography>

              <Box sx={{ display: "flex", flexDirection: "row", mt: "30px" }}>
                <AssistantItem
                  icon="/icons/assistant-icon-1.png"
                  title="No Hidden Costs"
                />
                <AssistantItem
                  icon="/icons/assistant-icon-2.png"
                  title="Immediate Hiring "
                />
                <AssistantItem
                  icon="/icons/assistant-icon-3.png"
                  title="Under Our Sponsorship"
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
      {screenWidth < 768 ? (
        // Mobile view
        <Box
          component="img"
          src="/images/homecare-assist.png"
          alt="Home Care Logo"
          sx={{
            width: "75%",
            marginRight:"-136px" ,
            maxHeight: "300px", 
            height: "auto",
            marginBottom:"-120px" ,
          }}
        />
      ) : (
        // Computer view
        <Box
          component="img"
          src="/images/homecare-assist.png"
          alt="Home Care Logo"
          sx={{
            width: "auto",
            borderRadius: "100%",
            maxHeight: "500px",
            height: "auto",

          }}
        />
      )}
          </Grid>
          
        </Grid>

      </Box>


      <OurServices />
      <FaqPage />

      <Map />

      {/* <div className="flex justify-center items-center h-screen bg-gray-100">
        <div className="max-w-md rounded-xl shadow-xl overflow-hidden bg-white">
          <Link to={`/package4`} className="block">
            <div className="p-6 text-center">
              <FontAwesomeIcon
                icon={faBroom}
                size="3x"
                className="text-gray-500 700 mb-4"
              />
              <h2 className="text-4xl font-bold text-gray-500 mb-3">
                Monthly Maid Service
              </h2>
              <p className="text-black">
                Discover the art of clean with our minimalist approach to home
                care.
              </p>
            </div>
            <div className="px-8 pb-8">
              <img
                src="/package4.jpg"
                alt="Maid"
                className="w-full h-60 object-cover rounded-lg mb-5"
              />
              <h3 className="text-2xl font-semibold text-white mb-3">
                Simplify Your Life
              </h3>
              <button className="w-full py-3 bg-gradient-to-r from-blue-500 to-blue-700 text-white font-semibold rounded-lg shadow-md hover:bg-gradient-to-br focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-300 ease-in-out flex items-center justify-center">
                Get Started{" "}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  size="lg"
                  className="ml-2"
                />
              </button>
            </div>
          </Link>
        </div>
      </div> */}
    </div>
  );
}
