import React, { useState } from 'react';

const AboutusDescription = () => {
  const [imageAlt, setImageAlt] = useState('Logo');

  const handleImageError = () => {
    setImageAlt('Image not found');
  };

  const handleContactClick = () => {
    const footerElement = document.getElementById("footer");
    if (footerElement) {
      footerElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="bg-blue-100">
      <div className="container mx-auto py-12 px-6 flex flex-wrap items-center justify-between">
        <div className="w-full md:w-1/4 flex justify-center md:justify-start pb-10">
          <img 
            src="aboutUs/images/homecare-assist.png" 
            alt={imageAlt} 
            className="max-w-xs md:max-w-md lg:max-w-lg xl:max-w-xl" 
            onError={handleImageError}
          />
        </div>
        <div className="w-full md:w-3/4">
          <h1 className="text-5xl font-semibold mb-4 text-blue-800" style={{ fontFamily: "'Your Custom Font', sans-serif" }}>About Us</h1>
          <div className="h-1 w-24 bg-orange-500"></div>
          <p className="text-gray-700 text-lg my-4 text-justify">
            Tadbeer Home Care is a collaboration of experienced professionals in the industry, operated under the Ministry of Human Resources and Emratization. We provide a one-stop solution for recruiting, supplying, and training domestic workers in many different areas, such as cleaning, cooking, and babysitting. We also provide typing services for domestic worker visas, medical typing, Emirates ID typing, and health insurance.
          </p>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleContactClick} style={{ fontFamily: "'Your Custom Font', sans-serif" }}>
            Contact Us
          </button>
        </div>
      </div>
    </div>
  );
};

export default AboutusDescription;
